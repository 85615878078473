import type { Entity, EntityType } from '@/features/core/entity-repository';
import type { Storage } from '@/features/core/storage';
import type { PipelinePlugin } from '@ads/plugin-pipeline';

interface RemoveEntityByIdPluginDTO {
  ids: string[];
  entityType?: EntityType<Entity> | undefined;
}

/**
 * Remove order metadata with specific IDs
 */
export class RemoveEntityByIdPlugin<T extends RemoveEntityByIdPluginDTO>
  implements PipelinePlugin<T>
{
  constructor(private storage: Storage) {}

  public async execute(dto: T): Promise<T> {
    await this.storage.removeSeveral(
      dto.entityType as unknown as EntityType<Entity>,
      {
        ids: dto.ids,
      },
    );
    return dto;
  }
}
