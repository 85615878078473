import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { errorPlugin } from '@/features/core/errors';
import { UpdateOrdersError } from '../errors';
import type { Ref, WatchStopHandle } from 'vue';
import { ref, watch } from 'vue';
import { Order } from '../entities';
import type {
  UseOrders,
  UseOrdersFilters,
  UseOrdersSearchFilters,
} from '../types';
import type { StorageFilter } from '@/features/core/storage';

const loading = ref(false);
export function useOrders(): UseOrders {
  const orders: Ref<Order[]> = ref([]);
  const filters = ref<UseOrdersFilters>({});
  const error = ref({
    name: '',
    message: '',
  });
  let unwatchOrdersList: WatchStopHandle | null = null;
  let unwatchSearchedOrders: WatchStopHandle | null = null;

  const limit = 100;
  let offset = 0;

  function setFilters(filterParams: UseOrdersFilters) {
    filters.value = filterParams;
  }

  const loadOrders = async (params: UseOrdersFilters) => {
    setFilters(params);
    await updateOrders();
  };

  function getFiltering() {
    const filtering: Record<string, StorageFilter> = {};

    if ('isCheckedIn' in filters.value) {
      filtering['checkIn.isCheckedIn'] = {
        equals: filters.value.isCheckedIn,
      };
    }

    if ('localStatus' in filters.value) {
      filtering.localStatus = { anyOf: filters.value.localStatus };
    }

    if (filters.value.temperatureClasses?.length) {
      filtering.temperatureClasses = {
        anyOf: filters.value.temperatureClasses,
      };
    }

    if (filters.value.searchBy && filters.value.search) {
      filtering[filters.value.searchBy] = { startsWith: filters.value.search };
    }

    return filtering;
  }

  async function updateOrders() {
    if (unwatchSearchedOrders) {
      unwatchSearchedOrders();
      unwatchSearchedOrders = null;
    }

    const filtering = getFiltering();
    try {
      loading.value = true;

      const ordersList = await entityRepositoryPlugin.get().getAll(Order, {
        filter: filtering,
        sortBy: filters.value.sortBy ?? 'startTime',
        sortDir: filters.value.sort,
        offset,
        limit,
      });

      orders.value = ordersList.value;

      if (unwatchOrdersList) {
        unwatchOrdersList();
        unwatchOrdersList = null;
      }

      unwatchOrdersList = watch(
        ordersList,
        () => {
          orders.value = ordersList.value;
        },
        { deep: true },
      );

      if (shouldResetOffset(filters.value)) {
        offset = 0;
      } else {
        offset += limit;
      }

      return orders;
    } catch (error) {
      errorPlugin.get().handle(new UpdateOrdersError());
    } finally {
      loading.value = false;
    }
  }

  function canSearch(params: UseOrdersSearchFilters) {
    return params.search?.length && params.search.length >= 2;
  }

  const searchedOrders: Ref<Order[]> = ref([]);
  async function searchOrders(params: UseOrdersSearchFilters) {
    if (canSearch(params)) {
      setFilters({
        ...params,
      });

      const filtering = getFiltering();

      const ordersList = await entityRepositoryPlugin.get().getAll(Order, {
        filter: filtering,
        sortBy: filters.value.sortBy ?? 'startTime',
        sortDir: filters.value.sort,
        offset,
        limit,
      });

      const searchOrdersLists = ordersList.value.filter((order: Order) => {
        return (
          order.orderReference.includes((params.search as string).trim()) ||
          (params.searchQueries.includes('pickupCode')
            ? order.pickupCode.includes((params.search as string).trim())
            : false)
        );
      });

      searchedOrders.value = searchOrdersLists;

      if (unwatchSearchedOrders) {
        unwatchSearchedOrders();
        unwatchSearchedOrders = null;
      }

      unwatchSearchedOrders = watch(
        searchOrdersLists,
        () => {
          searchedOrders.value = searchOrdersLists;
        },
        {
          deep: true,
        },
      );
    }
  }

  function shouldResetOffset(params?: UseOrdersFilters) {
    return !!params;
  }

  return {
    orders,
    filters,
    loading,
    error,
    loadOrders,
    searchOrders,
    searchedOrders,
  };
}
