import { $t } from '@/i18n';
import type { ConfigurationService } from '@/features/configuration';
import type { Order } from '@/features/orders/entities';
import { isDueSoonOrder } from '@/features/orders/helpers/isDueSoonOrder';
import { OrderLocalStatus } from '@/features/orders/types';
import type { PushNotificationDTO } from '@/features/push-notification/types';
import { NotificationType } from '@/features/push-notification/types';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import { FetchEntityPlugin } from './fetch-entity';
import { RecognizeEntityTypePlugin } from './recognize-entity-type';
import { createOrderScheduledNotificationBody } from '../helpers/createOrderScheduledNotificationBody';
import { NotificationTags } from '../types';

/**
 * Add due soon order notification to the native notifications DTO array
 */

export class CreateNotificationForDueSoonOrdersPlugin
  implements PipelinePlugin<PushNotificationDTO>
{
  public readonly requiredPlugins = [
    FetchEntityPlugin,
    RecognizeEntityTypePlugin,
  ];

  constructor(private configurationService: ConfigurationService) {}

  public async execute(
    dataTransferObject: PushNotificationDTO,
  ): Promise<PushNotificationDTO> {
    const pickingBuffer = await this.configurationService.getFeatureOption(
      'picking',
      'pickingBuffer',
      'number',
    );
    const orders = dataTransferObject.entities as Order[];
    const dueSoonOrders = orders.filter(
      (order) =>
        isDueSoonOrder(order.startTime, pickingBuffer) &&
        order.localStatus === OrderLocalStatus.PickingReady,
    );

    if (!dueSoonOrders.length) return dataTransferObject;
    const dueSoonOrdersIds = dueSoonOrders.map((order) => order.id);

    const notificationTitle: string = $t(
      'components.native-notification.due-soon-order.title',
      {
        count: dueSoonOrders.length,
      },
    );

    const notificationBody =
      createOrderScheduledNotificationBody(dueSoonOrders);

    dataTransferObject.notifications.queue({
      payload: {
        body: notificationBody,
        tag: NotificationTags.DueSoonOrder,
        data: {
          route: '/#picking?id={ID}',
        },
      },
      title: notificationTitle,
      type: NotificationType.DueSoonOrders,
      ids: dueSoonOrdersIds,
    });

    return dataTransferObject;
  }
}
