import type { RouteRecordRaw, Router } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import { oauthServicePlugin } from '@/features/oauth/plugin';
import { $t } from '@/i18n';
import { MultiPluginFactory, ProviderPluginFactory } from '../plugin';
import { loggerServicePlugin } from '../logger';
import { shouldLogRouteChange } from '@/utils/helpers/shouldLogRouteChange';

export let lastPosition = { left: 0, top: 0 };

// TODO: Refactor to "dynamic route registration". See approach of https://gitlab.aldidevops.com/aci/commerce-solutions/fulfilment-mobility-solution/-/blob/development/documentation/modules/packages/pages/router.adoc
// Implementation; https://gitlab.aldidevops.com/aci/commerce-solutions/fulfilment-mobility-solution/-/tree/development/packages/router
// This approach can be helpful to avoid that routes are loaded into Service Worker Scope
// We should create a Picking and Handover Feature and these Feature will register all necessary routes. Same for Login feature etc.
export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    children: [
      {
        path: '/',
        name: 'default',
        meta: {
          showCustomerCheckinBanner: true,
        },
        component: () => import('@/features/orders/views/OrdersOverview.vue'),
      },
      {
        path: '/user-report',
        name: 'user-report',
        component: () => import('@/features/user-reports/views/UserReport.vue'),
      },
      {
        path: '/online-product-search',
        name: 'product-search',
        meta: {
          appCrashFeature: {
            trackRoute: true,
          },
        },
        component: () =>
          import('@/features/product-search/views/ProductSearch.vue'),
      },
      {
        path: '/login',
        name: 'login',
        meta: {
          layout: 'Login',
          skipRouteLogs: true,
        },
        component: () => import('@/features/login/views/Login.vue'),
      },
      {
        path: 'start',
        name: 'start',
        meta: {
          preventRedirectFromOrdersList: true,
          skipRouteLogs: true,
        },
        component: () => import('@/features/start/views/StartPage.vue'),
      },
      {
        path: '/',
        name: 'verification',
        component: () => import('@/features/ui/layouts/DefaultLayout.vue'),
        children: [
          {
            path: '/age-verification/:id',
            name: 'age-verification',
            props: true,
            meta: {
              disabledLogout: true,
              appCrashFeature: {
                trackRoute: true,
              },
            },
            component: () =>
              import('@/features/age-verification/views/AgeVerification.vue'),
          },
        ],
      },
      {
        path: '/print/:id',
        name: 'label-print',
        children: [
          {
            path: 'zebra',
            name: 'label-print-zebra',
            props: true,
            meta: {
              showSameDayNotification: true,
            },
            component: () =>
              import('@/features/label-print/view/ZebraLabelPrinter.vue'),
          },
          {
            path: 'zebra-qr',
            name: 'label-print-zebra-qr',
            props: true,
            meta: {
              showSameDayNotification: true,
            },
            component: () =>
              import('@/features/label-print/view/ZebraLabelQRCodePrinter.vue'),
          },
          {
            path: '',
            name: 'label-print-default',
            props: true,
            meta: {
              showSameDayNotification: true,
            },
            component: () =>
              import('@/features/label-print/view/LabelPrinter.vue'),
          },
        ],
        component: () => import('@/features/ui/layouts/DefaultLayout.vue'),
      },
    ],
    name: 'home',
    component: () => import('@/features/ui/layouts/DefaultLayout.vue'),
  },

  {
    name: 'picking',
    path: '/picking/:id',
    component: () => import('@/features/ui/layouts/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'picking-layout',
        component: () => import('@/features/ui/layouts/PickingLayout.vue'),
        children: [
          {
            path: 'picking-start',
            name: 'picking-start',
            meta: {
              layout: 'Picking',
              activatedScreenWakeLock: true,
              showSameDayNotification: false,
              showCustomerCheckinBanner: false,
            },
            props: true,
            component: () =>
              import('@/features/picking/views/PickingStartedPage.vue'),
          },
          {
            path: 'overview',
            name: 'picking-order',
            meta: {
              layout: 'Picking',
              disabledLogout: true,
              activatedScreenWakeLock: true,
              showSameDayNotification: true,
              blockGoingBack: {
                targetRoutes: ['default', 'picking-start'],
                message: $t(
                  'components.router.picking-block-going-back.message',
                ),
              },
              appCrashFeature: {
                trackRoute: true,
              },
            },
            props: true,
            component: () =>
              import('@/features/picking/views/PickingOrder.vue'),
          },
          {
            path: 'replacement/:orderItemId',
            name: 'replacement',
            meta: {
              layout: 'Picking',
              disabledLogout: true,
              activatedScreenWakeLock: true,
              showSameDayNotification: true,
              showCustomerCheckinBanner: false,
              appCrashFeature: {
                trackRoute: true,
              },
            },
            props: true,
            component: () =>
              import('@/features/replacement/views/Replacement.vue'),
          },
          {
            path: 'complete-picking-note',
            name: 'complete-picking',
            meta: {
              layout: 'Picking',
              disabledLogout: true,
              activatedScreenWakeLock: true,
              showSameDayNotification: true,
              showCustomerCheckinBanner: false,
              appCrashFeature: {
                trackRoute: true,
              },
            },
            props: true,
            component: () =>
              import('@/features/picking/views/PickingCompleteNote.vue'),
          },
          {
            path: 'review',
            name: 'review-order',
            meta: {
              layout: 'Picking',
              disabledLogout: true,
              activatedScreenWakeLock: false,
              showSameDayNotification: true,
              showCustomerCheckinBanner: false,
              appCrashFeature: {
                trackRoute: true,
              },
            },
            props: true,
            component: () => import('@/features/review/views/ReviewOrder.vue'),
          },
          {
            path: 'bags',
            name: 'order-bags',
            meta: {
              layout: 'Picking',
              disabledLogout: true,
              activatedScreenWakeLock: false,
              showSameDayNotification: true,
              showCustomerCheckinBanner: false,
              appCrashFeature: {
                trackRoute: true,
              },
            },
            props: true,
            component: () =>
              import('@/features/review/views/AssigningBags.vue'),
          },
          {
            path: 'labels',
            name: 'picking-labels',
            meta: {
              layout: 'Picking',
              disabledLogout: true,
              activatedScreenWakeLock: false,
              showSameDayNotification: true,
              showCustomerCheckinBanner: false,
              appCrashFeature: {
                trackRoute: true,
              },
            },
            props: true,
            component: () =>
              import('@/features/review/views/AssigningLabels.vue'),
          },
          {
            path: 'bag-position',
            name: 'picking-bag-position',
            meta: {
              layout: 'Picking',
              disabledLogout: true,
              activatedScreenWakeLock: false,
              showSameDayNotification: true,
              showCustomerCheckinBanner: false,
              preventRedirectFromOrdersList: true,
              appCrashFeature: {
                trackRoute: true,
              },
            },
            props: true,
            component: () =>
              import('@/features/review/views/ScanBagPosition.vue'),
          },
          {
            path: 'complete',
            name: 'complete-picking-order',
            meta: {
              layout: 'Picking',
              disabledLogout: true,
              activatedScreenWakeLock: false,
              showSameDayNotification: true,
              showCustomerCheckinBanner: false,
              appCrashFeature: {
                trackRoute: true,
              },
              preventRedirectFromOrdersList: true,
            },
            props: true,
            component: () => import('@/features/review/views/CustomerNote.vue'),
          },
        ],
      },
    ],
    meta: {
      showCustomerCheckinBanner: true,
    },
  },
  {
    name: 'handover',
    path: '/handover/:id',
    component: () => import('@/features/ui/layouts/DefaultLayout.vue'),
    children: [
      {
        path: 'overview',
        name: 'handover-order',
        meta: {
          activatedScreenWakeLock: false,
          showSameDayNotification: true,
          disabledLogout: true,
          appCrashFeature: {
            trackRoute: true,
          },
          preventRedirectFromOrdersList: true,
        },
        props: true,
        component: () => import('@/features/handover/views/HandoverOrder.vue'),
      },
      {
        path: 'customer-information',
        name: 'handover-customer-information',
        meta: {
          activatedScreenWakeLock: false,
          disabledLogout: true,
          showSameDayNotification: true,
          blockGoingBack: {
            targetRoutes: ['bags-collection'],
            message: $t('components.router.handover-block-going-back.message'),
          },
          appCrashFeature: {
            trackRoute: true,
          },
        },
        props: true,
        component: () =>
          import('@/features/handover/views/CustomerInformation.vue'),
      },
      {
        path: 'bags-collection',
        name: 'bags-collection',
        meta: {
          activatedScreenWakeLock: false,
          appCrashFeature: {},
        },
        props: true,
        component: () =>
          import('@/features/handover-preparation/views/BagsCollection.vue'),
      },
      {
        path: 'customer-note',
        name: 'handover-customer-note',
        meta: {
          layout: 'Picking',
          disabledLogout: true,
          activatedScreenWakeLock: true,
        },
        props: true,
        component: () =>
          import('@/features/ui/components/CustomerOverview.vue'),
      },
      {
        path: '/age-verification/:id',
        name: 'age-verification',
        props: true,
        meta: {
          disabledLogout: true,
          showSameDayNotification: true,
        },
        component: () =>
          import('@/features/age-verification/views/AgeVerification.vue'),
      },
      {
        path: 'restaging',
        name: 'restaging',
        meta: {
          layout: 'Picking',
          disabledLogout: true,
          activatedScreenWakeLock: false,
          showSameDayNotification: true,
          showCustomerCheckinBanner: false,
          preventRedirectFromOrdersList: true,
          appCrashFeature: {
            trackRoute: true,
          },
        },
        props: true,
        component: () => import('@/features/review/views/ScanBagPosition.vue'),
      },
    ],
  },

  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

export const router = ProviderPluginFactory.create<Router>({
  key: Symbol('Router'),
  defaultFactory: {
    create(app) {
      const router = createRouter({
        history: createWebHistory(),
        routes,
        scrollBehavior(to, from, savedPosition) {
          lastPosition = savedPosition
            ? savedPosition
            : {
                top: 0,
                left: 0,
              };
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(lastPosition);
            }, 10);
          });
        },
      });
      router.beforeEach(async (to, from, next) => {
        const authService = oauthServicePlugin.get();
        const isAuthenticated = await authService.isAuthenticated();
        if (to.name !== 'login' && !isAuthenticated) {
          next({ name: 'login' });
        } else {
          next();
        }
      });
      router.afterEach((to, from) => {
        const currentRoute = router.currentRoute.value;

        if (currentRoute.fullPath !== to.fullPath) {
          loggerServicePlugin
            .get()
            .warn(
              `Navigation to [${to.fullPath}] was not successful. Current route is [${currentRoute.fullPath}].`,
            );
          return;
        }

        if (shouldLogRouteChange(to, from)) {
          loggerServicePlugin
            .get()
            .warn(
              `Route changed from [${String(from.fullPath)}] to [${String(
                to.fullPath,
              )}]`,
            );
        }
      });
      app.use(router);
      return router;
    },
  },
});

export const routerConfigPlugin = MultiPluginFactory.with({
  router,
});

export default router;
