export const configSets = {
  trainingMode: {
    defaultConfig: {
      isActive: false,
    },
    selectiveConfigs: [
      {
        environmentCondition: /^http:\/\/localhost:\d+/,
        isActive: false,
      },
      {
        environmentCondition:
          /^https:\/\/training-picking-app.us.prod.commerce.ci-aldi.com/,
        isActive: true,
      },
    ],
  },
};
