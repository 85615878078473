import {
  EntityUpdatedEvent,
  OrderUpdateEvent,
} from '@/features/core/entity-repository/events';
import type { EventBus, EventBusEvent } from '@/features/core/event-bus';
import { isServiceWorkerProcess } from '@/features/push-notification/helpers/isServiceWorkerProcess';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import { Order } from '../entities';

interface UpdateOrderUIPluginDTO {
  ids: string[];
}

/**
 * Updates order UI
 */

export class UpdateOrderUIPlugin<T extends UpdateOrderUIPluginDTO>
  implements PipelinePlugin<T>
{
  constructor(private eventBusService: EventBus) {}

  public execute(dto: T): T {
    const isSW = isServiceWorkerProcess();
    let event: EventBusEvent;
    if (isSW) {
      event = new OrderUpdateEvent(JSON.stringify(dto.ids));
    } else {
      event = new EntityUpdatedEvent([
        {
          entity: Order,
          ids: dto.ids,
          updated: true,
        },
      ]);
    }
    this.eventBusService.emit(event);
    return dto;
  }
}
