
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import type { OrderItem } from '@/features/orders/types';
import { Button, WeightBlock } from '@/features/ui/components';
import { EditIcon } from '@/features/ui/icons';

enum Events {
  Restore = 'restore',
  Edit = 'edit',
}

export default defineComponent({
  name: 'CardRowFloatingFooterWithWeight',
  props: {
    orderItem: {
      type: Object as PropType<OrderItem>,
      default: () => ({}),
    },
    isControlsDisabled: {
      type: Boolean,
      default: false,
    },
    hideControls: {
      type: Boolean,
      default: false,
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
    quantity: {
      type: Number,
      default: 0,
    },
    quantityOriginal: {
      type: Number,
      default: 0,
    },
    amount: {
      type: Number,
      default: 0,
    },
    amountOriginal: {
      type: Number,
      default: 0,
    },
    isRestorable: {
      type: Boolean,
      default: true,
    },
    currentTab: String,
    showOriginal: {
      type: Boolean,
      default: true,
    },
    hideQuantityOriginal: {
      type: Boolean,
      default: false,
    },
  },
  emits: Object.values(Events),
  components: {
    WeightBlock,
    EditIcon,
    Button,
  },
  setup(props, { emit }) {
    const handleEditButton = () => {
      emit(Events.Edit, props.orderItem.id);
    };

    const isVisibleEditButton = computed(
      () => !props.isControlsDisabled && !props.hideControls,
    );

    return {
      handleEditButton,
      isVisibleEditButton,
      Events,
    };
  },
});
