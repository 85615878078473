import type { LoggerService } from '@/features/core/logger/services/logger-service';
// FIXME: Auth Service is used by Service Worker why it should not depend on Vue Framework.
import { ref } from 'vue';
import qs from 'qs';
import type { Cancellable } from '@/utils/types';
import type { Storage } from '@/features/core/storage';
import type { USER_ROLE } from '@/features/user/entities';
import { User } from '@/features/user/entities';
import { transformStringToType } from '@/utils/helpers/transformStringToType';
import type { OauthProviderConfig } from '@/features/oauth/types';
import { VerificationDialogShow } from '@/features/login/events';
import { AuthData } from './entity';
import type {
  AuthClientOptions,
  DeviceTokenPayload,
  DeviceTokenPayloadSub,
  UserTokenPayload,
} from './types';
import { SessionRequestError } from './error';
import type { BaseApiClient } from '../api';
import type { ErrorHandler } from '../errors';
import type { EventBus } from '../event-bus';
import { sleep } from '@/utils/helpers/event-loop-waiters';
import type { OauthTokenAuthResponse } from '@/features/login/types';
import type { AxiosRequestConfig } from 'axios';

export class AuthService {
  public showOfflineDialog = ref(false);
  private userTokenListeners = new Set<(token: string | undefined) => void>();
  private refreshTokenListeners = new Set<
    (token: string | undefined) => void
  >();
  private deviceTokenListeners = new Set<(token: string) => void>();
  private refreshTokenInProgress = false;
  private refreshTokenCancellationRequested = false;

  constructor(
    private options: AuthClientOptions,
    private storage: Storage,
    private errorHandler: ErrorHandler,
    private empowerId: BaseApiClient,
    private eventBus: EventBus,
    private logger: LoggerService,
  ) {}

  async getUserId(): Promise<string | undefined> {
    const data = await this.getAuthData();
    return data.id;
  }

  public requestCancelRefreshToken(): void {
    if (!this.refreshTokenInProgress) {
      return;
    }

    this.refreshTokenCancellationRequested = true;
  }

  async getUserToken(): Promise<string | undefined> {
    const data = await this.getAuthData();
    return data.userToken;
  }

  async getRefreshToken(): Promise<string | undefined> {
    const data = await this.getAuthData();
    return data.refreshToken;
  }

  async getDeviceToken(): Promise<string | undefined> {
    const data = await this.getAuthData();
    return data.deviceToken;
  }

  async getUserTokenCreationTime(): Promise<string | undefined> {
    const data = await this.getUserPayload();
    // token 'iat' value is stored in seconds (need to convert to milliseconds)
    return data?.iat ? new Date(data.iat * 1000).toISOString() : undefined;
  }

  async merchantReference(): Promise<string | undefined> {
    const payload = await this.getDevicePayload();
    if (!payload) {
      return undefined;
    }

    const sub = JSON.parse(payload.sub) as DeviceTokenPayloadSub;
    if (!this.isDeviceTokenPayloadSub(sub)) {
      return undefined;
    }

    return sub?.merchant_reference;
  }

  async getUserPayload(): Promise<UserTokenPayload | undefined> {
    const token = await this.getUserToken();
    if (token) {
      return this.parseJwtToken(token);
    }
  }

  async getDevicePayload(): Promise<DeviceTokenPayload | null> {
    const data = await this.getAuthData();
    return data.deviceToken ? this.parseJwtToken(data.deviceToken) : null;
  }

  async updateUserToken(token: string): Promise<void> {
    const data = await this.getAuthData();

    data.userToken = token;

    await this.storage.save(data);
    await Promise.all(
      Array.from(this.userTokenListeners).map((callback) => callback(token)),
    );
  }

  async updateRefreshToken(token: string): Promise<void> {
    const data = await this.getAuthData();

    data.refreshToken = token;

    await this.storage.save(data);

    this.refreshTokenListeners.forEach((callback) => callback(token));
  }

  async updateDeviceToken(token: string): Promise<void> {
    const data = await this.getAuthData();

    data.deviceToken = token;

    await this.storage.save(data);

    this.deviceTokenListeners.forEach((callback) => callback(token));
  }

  async removeUserToken(): Promise<void> {
    const data = await this.getAuthData();

    data.userToken = undefined;
    data.refreshToken = undefined;

    await this.storage.save(data);
    this.userTokenListeners.forEach((callback) => callback(undefined));
    this.refreshTokenListeners.forEach((callback) => callback(undefined));
  }

  async removeDeviceToken(): Promise<void> {
    const data = await this.getAuthData();

    data.deviceToken = undefined;
    data.userToken = undefined;

    await this.storage.save(data);

    this.deviceTokenListeners.forEach((callback) => callback(''));
  }

  onUserTokenChange(callback: (token?: string) => void): Cancellable {
    this.userTokenListeners.add(callback);

    return {
      cancel: () => this.userTokenListeners.delete(callback),
    };
  }

  onRefreshTokenChange(callback: (token?: string) => void): Cancellable {
    this.refreshTokenListeners.add(callback);

    return {
      cancel: () => this.refreshTokenListeners.delete(callback),
    };
  }

  onDeviceTokenChange(callback: (token?: string) => void): Cancellable {
    this.deviceTokenListeners.add(callback);

    return {
      cancel: () => this.deviceTokenListeners.delete(callback),
    };
  }

  async getMerchantReference(): Promise<string | undefined> {
    const payload = await this.getUserPayload();

    if (!payload) {
      return await this.merchantReference();
    }
    const merchant = /Division-(\d{3})/.exec(
      payload['Person.ExtensionAttribute1'],
    );
    if (!merchant) return;
    const attr3 = payload['Person.ExtensionAttribute3'];
    if (!attr3) return;
    return merchant[1] + '-' + attr3;
  }

  async getUserRoles(): Promise<USER_ROLE[] | undefined> {
    const payload = await this.getUserPayload();

    if (!payload) {
      return;
    }

    const trackingOnlyGroupListArr = Array.isArray(
      payload.TrackingOnlyGroupList,
    )
      ? payload.TrackingOnlyGroupList
      : (transformStringToType(
          payload.TrackingOnlyGroupList,
          'array',
        ) as string[]);

    return trackingOnlyGroupListArr.reduce((arr: USER_ROLE[], role) => {
      const match = /commerce_[\w]+_(Picker)/.exec(role);
      if (match) {
        arr.push(match[1].toLowerCase() as USER_ROLE);
      }
      return arr;
    }, []);
  }

  async getUserEmail(): Promise<string | undefined> {
    const payload = await this.getUserPayload();
    return payload?.sub;
  }

  async checkToken(
    httpMethod: AxiosRequestConfig['method'] | undefined,
    config: OauthProviderConfig | undefined,
  ): Promise<string | undefined> {
    try {
      const decodedUserToken = await this.getUserPayload();
      const deviceToken = await this.getDeviceToken();
      const userToken = await this.getUserToken();
      if (config?.useDeviceToken) {
        if (deviceToken) {
          return deviceToken;
        } else {
          this.errorHandler.handle(new SessionRequestError());
          return;
        }
      }
      if (decodedUserToken) {
        // User session exists.
        const currentTime = Math.round(Date.now() / 1000);
        const expTime = decodedUserToken.exp;
        if (expTime - 5 < currentTime) {
          this.logger.info('User Token expired');
          const refreshedUserToken = await this.refreshTokenWithRetry(config);
          if (refreshedUserToken) {
            return refreshedUserToken;
          }
        } else {
          // user session is not expired and can be used
          return userToken;
        }
      }
      if (
        deviceToken &&
        config?.useDeviceToken !== false &&
        httpMethod &&
        !['PATCH', 'POST', 'DELETE', 'PUT'].includes(httpMethod.toUpperCase())
      ) {
        // User session wasn't used and Request Method is not PATCH why device session will be returned
        return deviceToken;
      }
      if (config) this.errorHandler.handle(new SessionRequestError());
    } catch (e) {
      this.errorHandler.handle(e);
    }
  }

  public async forceRefreshUserToken(): Promise<boolean> {
    this.logger.info('User Token force-refresh');
    const token = await this.refreshTokenWithRetry(
      this.options.config?.providers['empowerId'],
    );
    return Boolean(token);
  }

  async handleRefreshToken(): Promise<void> {
    try {
      await this.refreshToken(this.options.config?.providers['empowerId']);
    } catch (e) {
      await this.removeUserToken();
      this.eventBus.emit(new VerificationDialogShow());
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      window?.newrelic?.noticeError('No Session for the Request is available!');
    }
  }

  async isSessionExpired(): Promise<boolean | undefined> {
    const userToken = await this.getUserToken();
    if (userToken) {
      const token = await this.checkToken(undefined, undefined);
      return !token;
    }
    return false;
  }

  async isDeviceTokenExpired(): Promise<boolean | undefined> {
    const deviceToken = await this.getDevicePayload();
    if (deviceToken) {
      return deviceToken.exp <= new Date().getTime() / 1000;
    }
    return true;
  }

  async checkSessionExpiration(): Promise<void> {
    const user = await this.getUserData();

    if (user.id) {
      // User is logging.
      const expiredSession = await this.isSessionExpired();

      if (!expiredSession) return;

      // FIXME: Auth Service is used by Service Worker where "window" is not available
      if (window?.navigator?.onLine) {
        await this.handleRefreshToken();
      } else {
        this.showOfflineDialog.value = true;
      }
    }

    // FIXME: Auth Service is used by Service Worker where "window" is not available
    window?.addEventListener('online', () => {
      if (this.showOfflineDialog.value) {
        setTimeout(() => {
          this.showOfflineDialog.value = false;
          void this.handleRefreshToken();
        }, 1200);
      }
    });
  }

  async canLoadData(): Promise<boolean> {
    return Boolean(
      (await this.getUserToken()) || (await this.getDeviceToken()),
    );
  }

  private isDeviceTokenPayloadSub(sub: unknown): sub is DeviceTokenPayloadSub {
    return (sub as DeviceTokenPayloadSub)?.merchant_reference !== undefined;
  }

  private async getAuthData(): Promise<AuthData> {
    const [data] = await this.storage.getAll(AuthData);
    return AuthData.from(data);
  }

  private async getUserData(): Promise<User> {
    const [data] = await this.storage.getAll(User);
    return User.from(data);
  }

  private parseJwtToken(token: string): UserTokenPayload {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload) as UserTokenPayload;
  }

  private async refreshToken(
    config: OauthProviderConfig | undefined,
  ): Promise<string | undefined> {
    const refreshToken = await this.getRefreshToken();
    if (!config) return;

    const params = {
      client_id: config.clientId,
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      scope: config.scope,
    };

    const token: { data: OauthTokenAuthResponse } =
      await this.empowerId.client.post(config.tokenUri, qs.stringify(params), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        disableErrorHandling: true,
      });

    await this.updateUserToken(token.data.id_token);
    await this.updateRefreshToken(token.data.refresh_token);

    return token.data.id_token;
  }

  private async refreshTokenWithRetry(
    config: OauthProviderConfig | undefined,
    retryAttemptNumber = 0,
  ): Promise<string | undefined> {
    this.refreshTokenInProgress = true;
    const infoLogRetrySuffix =
      retryAttemptNumber > 0 ? ` (retry attempt #${retryAttemptNumber})` : '';
    const infoMessage = `User Token will be refreshed${infoLogRetrySuffix}`;
    this.logger.info(infoMessage);

    try {
      const refreshedUserToken = await this.refreshToken(config);
      this.logger.info('User Token has been refreshed successfully');
      this.refreshTokenInProgress = false;

      return refreshedUserToken;
    } catch (error: unknown) {
      if (this.refreshTokenCancellationRequested) {
        this.refreshTokenCancellationRequested = false;
        this.refreshTokenInProgress = false;
        this.logger.info('User Token refresh has been cancelled');

        return;
      }

      /**
       * If the process fails for any reason, we retry 3 times
       *
       * - 1st time: immediately
       * - 2nd time: after 5 seconds
       * - 3rd time: after 10 seconds
       *
       * If the request fails again after this, we fall back to
       * the existing "failed to refresh" logic
       */

      if (retryAttemptNumber < 3) {
        const retryDelay = 5000 * retryAttemptNumber;
        const nextRetryAttemptNumber = retryAttemptNumber + 1;
        const waitTimeString =
          retryDelay > 0
            ? `waiting for ${Math.round(retryDelay / 1000)}s before retrying`
            : 'retrying immediately';
        this.logger.warn(
          `Refreshing the user token failed, ${waitTimeString}`,
          error instanceof Error ? error : undefined,
        );
        if (retryDelay > 0) {
          await sleep(retryDelay);
        }

        return this.refreshTokenWithRetry(config, nextRetryAttemptNumber);
      }

      this.refreshTokenInProgress = false;
      this.logger.error(
        `Refreshing the user token failed after ${retryAttemptNumber} retries`,
        error instanceof Error ? error : undefined,
      );

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      window?.newrelic?.noticeError(error);
    }
  }
}
