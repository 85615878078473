import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted } from 'vue';
import router from '@/features/core/router';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import { Dialog } from '@/features/ui/components';
import { ChangeUrlEvent } from '@/features/route-leave-guard/events';
import { useRouteLeaveGuard } from '../composables';

export default /*@__PURE__*/_defineComponent({
  __name: 'RouteLeaveGuard',
  setup(__props) {

const {
  closeDialog,
  isDialogVisible,
  dialogText,
  setupNavigationGuard,
  isSameDayDialogVisible,
  cancelSameDayNotification,
  unregisterNavigationGuard,
} = useRouteLeaveGuard();

onMounted(() => {
  setupNavigationGuard();
});

eventBusServicePlugin.get().on(ChangeUrlEvent, (data) => {
  void (async () => {
    if (data.options?.bypassRouteNavigationGuard) {
      unregisterNavigationGuard();
      await router.get().push(data.url);
      setupNavigationGuard();
      return;
    }

    await router.get().push(data.url);
  })();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(Dialog), {
      confirmText: _ctx.$t('components.route-leave-guard.dialog-confirm.text'),
      contentText: _ctx.$t('components.route-leave-guard.dialog-content.text'),
      isTitleCentered: false,
      "title-text": _unref(dialogText),
      visible: _unref(isDialogVisible),
      fullWidth: "",
      isSpaceToContentExist: "",
      showOnlyConfirm: "",
      onConfirmed: _unref(closeDialog)
    }, null, 8, ["confirmText", "contentText", "title-text", "visible", "onConfirmed"]),
    _createVNode(_unref(Dialog), {
      isTitleCentered: false,
      "show-only-confirm": true,
      visible: _unref(isSameDayDialogVisible),
      "confirm-text": "Close",
      "content-text": "Please complete this order first before starting another.",
      "title-text": "Order cannot be opened",
      onConfirmed: _unref(cancelSameDayNotification)
    }, null, 8, ["visible", "onConfirmed"])
  ], 64))
}
}

})