import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { $t } from '@/i18n';

import { EntityUpdatedEvent } from '@/features/core/entity-repository';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import {
  startHandoverProcess,
  useCheckInOrders,
  useFiltersAndTabs,
  useOnlineStatus,
  useOrders,
} from '../composables';

import { FallbackType, OrderActionStatus } from '../types';
import { isOrderHandover } from '@/features/orders/helpers';
import { Order } from '../entities';
import { useRouter } from 'vue-router';
import type { OrderCard } from '../components';
import { ordersServicePlugin } from '../services';
import { errorPlugin } from '@/features/core/errors';
import { dataFetchQueueServicePlugin } from '@/features/data-fetch-queue';

import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';

const isNotePopupVisible = ref(false);
const interactedOrder = ref({});

const { checkInBanner } = useCheckInOrders();
export const useOrdersOverview = () => {
  /**
   *
   * Refs & Constants
   *
   */
  const isUserTokenRefreshing: Ref<boolean> = ref(false);
  const isRefreshLoading: Ref<boolean> = ref(false);

  const { loading: ordersLoading } = useOrders();

  const { isFiltering, currentTab } = useFiltersAndTabs();

  const disabledProcess: Ref<boolean> = ref(false);

  /**
   *
   * Computed Properties
   *
   */

  const isPageLoadingVisible = computed(
    () => isUserTokenRefreshing.value || ordersLoading.value,
  );

  const screenLoadingTitle = computed(() =>
    isUserTokenRefreshing.value
      ? $t('pages.order-overview.refreshing-user-token.text')
      : '',
  );

  const fallbackType = computed(() => {
    if (currentTab.value.status === OrderActionStatus.picking) {
      if (isFiltering.value) {
        return FallbackType.noFilteredPickingOrders;
      }

      return FallbackType.noPickingOrders;
    }

    return FallbackType.noHandoverOrders;
  });

  const router = useRouter();
  const startOrder = async (order: Order) => {
    if (isOrderHandover(order)) {
      await startHandoverProcess(order, disabledProcess);
      return;
    }

    await router.push({
      name: 'picking-start',
      params: {
        id: order.id,
      },
    });
  };

  const cardsRefs: Ref<{ [key: string]: typeof OrderCard }> = ref({});
  const setCardsRefs = (element: typeof OrderCard, cardId: string) => {
    cardsRefs.value[`card-${cardId}`] = element;
  };
  const setInteractedOrder = (order?: Order) => {
    if (order) {
      interactedOrder.value = order;
    }
  };
  const toggleNote = (order?: Order) => {
    setInteractedOrder(order);
    isNotePopupVisible.value = !isNotePopupVisible.value;
    checkInBanner.value = !isNotePopupVisible.value;
  };

  const { isOnline, showOfflineErrorToast } = useOnlineStatus();

  const { startTracking, stopTracking } = usePerformanceTracker();

  const forceUpdateOrderList = (orderIds: string[]) => {
    eventBusServicePlugin.get().emit(
      new EntityUpdatedEvent([
        {
          entity: Order,
          updated: true,
          ids: orderIds,
        },
      ]),
    );
  };
  const fetchNewOrders = async (ids: string[] | null = null) => {
    if (disabledProcess.value || isRefreshLoading.value) {
      return;
    }

    if (!isOnline.value) {
      showOfflineErrorToast();
      return;
    }

    const joinIdsOrEmpty: string = ids ? ids.join(', ') : '';
    disabledProcess.value = true;
    isRefreshLoading.value = true;

    try {
      startTracking(
        `fetch-new-orders-${joinIdsOrEmpty}`,
        PerformanceThresholdEnum.API_CALL,
      );

      const fetchedOrders = await ordersServicePlugin
        .get()
        .fetchOrdersByIds(ids, false);

      const fetchedOrderIds = fetchedOrders.map((o) => o.id);
      forceUpdateOrderList(fetchedOrderIds);

      /* If all orders were fetched, DataFetchQueue is outdated and a new request is unnecessary */
      if (ids === null) {
        await dataFetchQueueServicePlugin.get().removeAllOfEntity(Order);
      }
    } catch (error) {
      errorPlugin.get().handle(error);
    } finally {
      stopTracking(`fetch-new-orders-${joinIdsOrEmpty}`);
    }

    disabledProcess.value = false;
    isRefreshLoading.value = false;
  };

  return {
    isPageLoadingVisible,
    screenLoadingTitle,
    isRefreshLoading,

    fallbackType,

    disabledProcess,
    startOrder,
    setCardsRefs,
    cardsRefs,

    toggleNote,
    isNotePopupVisible,
    interactedOrder,

    checkInBanner,

    fetchNewOrders,

    isOnline,
  };
};
