import { MultiPluginFactory } from '@/features/core/plugin';
import { ordersPlugin } from '@/features/orders';
import { oauthLoginConfigPlugin } from '@/features/login';
import { oauthPlugin } from '@/features/oauth';
import { productSearchConfigPlugin } from '@/features/product-search';
import { productsPlugin } from '@/features/products';
import { barcodeConfigPlugin, barcodeServicePlugin } from '@/features/barcode';
import { configurationPlugin } from '@/features/configuration';
import { merchantConfigPlugin } from '@/features/merchant';
import { userHelperServicePlugin, userPlugin } from '@/features/user';
import { pushNotificationPlugin } from '@/features/push-notification';
import { syncSchedulerPlugin } from '@/features/sync-scheduler';
import { serviceWorkerPlugin } from '@/features/service-worker';
import { wakeLockPlugin } from '@/features/wake-lock';
import { sessionStorageConfigPlugin } from '@/features/session-storage';
import { processedOrderPlugin } from '@/features/processed-order';
import { appCrashPlugin } from '@/features/app-crash';
import { dataSyncPlugin } from '@/features/data-sync';
import { dataFetchQueuePlugin } from '@/features/data-fetch-queue';
import { dataBackgroundSyncPlugin } from '@/features/data-background-sync';
import { imageCachingQueuePlugin } from '@/features/imageCachingQueue';
import { appBootCheckerPlugin } from '@/features/app-boot-checker';
import { e2eTestHelperPlugin } from '@/features/e2e-test-helper';
import { clearNotificationConfigPlugin } from '@/features/clear-notification';
import { orderMetadataPlugin } from '@/features/orderMetadata';
import { deviceIdPlugin } from '@/features/device-id';
import { retrySynchronisationsPlugin } from '@/features/retry-synchronisation';
import { loggerTransportersPlugin } from '@/features/core/logger/transporter-plugins';
import { visibilityChangeLogPlugin } from '@/features/core/logger/other-plugins';
import { devicePlugin } from '@/features/device-info';
import { reportsPlugin } from '@/features/reports';
import { failedTransmissionsPlugin } from '@/features/transmissions';
import { deliveryUnitPlugin } from '@/features/delivery-unit';
import { deviceFeedbackServicePlugin } from '@/features/device-feedback';
import { userReportsPlugin } from '@/features/user-reports';

export const featuresPlugin = MultiPluginFactory.with({
  userHelperServicePlugin,
  merchantConfigPlugin,
  imageCachingQueuePlugin,
  syncSchedulerPlugin,
  clearNotificationConfigPlugin,
  configurationPlugin,
  productsPlugin,
  orderMetadataPlugin,
  processedOrderPlugin,
  ordersPlugin,
  sessionStorageConfigPlugin,
  userPlugin,
  serviceWorkerPlugin,
  oauthLoginConfigPlugin,
  productSearchConfigPlugin,
  oauthPlugin,
  barcodeConfigPlugin,
  pushNotificationPlugin,
  wakeLockPlugin,
  dataSyncPlugin,
  deliveryUnitPlugin,
  dataFetchQueuePlugin,
  dataBackgroundSyncPlugin,
  deviceIdPlugin,
  devicePlugin,
  loggerTransportersPlugin,
  appCrashPlugin,
  appBootCheckerPlugin,
  e2eTestHelperPlugin,
  barcodeServicePlugin,
  retrySynchronisationsPlugin,
  reportsPlugin,
  failedTransmissionsPlugin,
  deviceFeedbackServicePlugin,
  visibilityChangeLogPlugin,
  userReportsPlugin,
});
