import type { ComputedRef, Ref } from 'vue';

export const DESCRIPTION_MAX_LENGTH = 5000;

export interface UseUserReports {
  description: Ref<string>;
  handleSubmit: () => Promise<void>;
  hasError: ComputedRef<boolean>;
  processing: Ref<boolean>;
}

export type UserReport = {
  subject: string;
  description: string;
};
