import type { ApiClient } from '@/features/core/api';
import type { ErrorHandler } from '@/features/core/errors';
import { PostReportsRequestError } from '../errors';
import type { UserReport } from '../types';

export class UserReportsApiClient {
  constructor(private api: ApiClient, private errorHandler: ErrorHandler) {}

  async postReports(userReport: UserReport): Promise<void> {
    const request = {
      data: {
        type: 'reports',
        attributes: {
          ...userReport,
          appVersion: process.env.VUE_APP_VERSION,
        },
      },
    };

    try {
      await this.api.client.post(`/reports`, request, {
        disableErrorHandling: true,
      });
    } catch (error) {
      throw new PostReportsRequestError();
    }
  }
}
