import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, Transition as _Transition, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/ALDI-logo.svg'


const _hoisted_1 = { class: "root-wrapper" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "login-page" }
const _hoisted_4 = { class: "content" }
const _hoisted_5 = {
  key: 0,
  class: "page-title"
}
const _hoisted_6 = {
  key: 1,
  class: "page-title"
}
const _hoisted_7 = { class: "login-form" }

import type { Ref } from 'vue';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import type { LocationQueryValue } from 'vue-router';
import { useRoute } from 'vue-router';
import type { Subscription } from 'rxjs';
import { Button, Dialog, Error, Popup } from '@/features/ui/components';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';
import {
  clickCount,
  FeatureToggleSection,
  timeToResetClickCounter,
} from '@/features/feature-toggle';
import { sessionStoragePlugin } from '@/features/session-storage';
import type { Cancellable } from '@/utils/types';
import { environmentConfigurationPlugin } from '@/features/core/environment-configuration';
import { $t } from '@/i18n';
import { useChangeLog, useOauthLogin } from '../composables';
import type { UseOauthLoginConfig } from '../types';
import { SKIP_WAITING, UPDATE_SW } from '../types';
import { useSecretToggle } from '@/features/ui/composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  setup(__props) {

const trainingModeActive = Boolean(
  environmentConfigurationPlugin.get().getConfig('trainingMode')?.isActive,
);

const title = trainingModeActive
  ? $t('pages.login.header-title-training-mode.text')
  : $t('pages.login.header-title.text');

const versionNumber = ref<null | HTMLElement>(null);
const scrollTo = ref<null | HTMLElement>(null);
const route = useRoute();

const validateQuery = (
  query: Record<string, string | LocationQueryValue[] | null>,
) => {
  let filteredQuery = {};
  for (let queryKey in query) {
    if (typeof query[queryKey] === 'string') {
      filteredQuery = {
        ...filteredQuery,
        [queryKey]: query[queryKey],
      };
    }
  }
  return filteredQuery;
};
const validQuery: UseOauthLoginConfig = validateQuery(route.query);

const {
  loading,
  login,
  error,
  requestTokenSet,
  notify,
  notifyMessage,
  disableNotify,
} = useOauthLogin();
// add validate query

const isPageRedirected = computed(() => {
  const state = sessionStoragePlugin.get().getItem('state');
  return validQuery?.state && state;
});

const isPageRedirectedFromLogout = computed(() => {
  return sessionStoragePlugin.get().getItem('logout');
});

const isPageHasSameState = computed(() => {
  const state = sessionStoragePlugin.get().getItem('state');
  return validQuery.state === state;
});

const {
  version: changeLogVersion,
  content: changeLogContent,
  hasContent: changeLogHasContent,
} = useChangeLog();

const showChangeLog = ref(false);
const changeLogTitleText = $t('pages.login.change-log-dialog.title.text', {
  changeLogVersion,
});
const setChangeLogVisibility = (visible: boolean) => {
  showChangeLog.value = visible;
};

async function handleLogin() {
  const registration = await navigator?.serviceWorker?.getRegistration();
  if (registration) {
    registration?.active?.postMessage(UPDATE_SW);
  }

  await login();
}

const appVersion = process.env.VUE_APP_VERSION;

const showReloadMessage = computed(
  () => notificationPlugin.get().isShowMessage.value,
);

let refreshPageTimeout: null | ReturnType<typeof setTimeout> = null;

const refreshPageAfterUpdate = () => {
  // remove controllerchange listener in case of fired event during picking flow
  navigator.serviceWorker?.removeEventListener(
    'controllerchange',
    refreshPageAfterUpdate,
  );

  if (refreshPageTimeout) {
    clearTimeout(refreshPageTimeout);
  }

  const params = new URLSearchParams(window.location.search);
  params.set('version-updated', 'true');

  window.location.search = params.toString();
};

const updateSW = async (): Promise<void> => {
  const registration = await navigator.serviceWorker.getRegistration();
  if (registration && registration.waiting) {
    registration.waiting.postMessage(SKIP_WAITING);
    // refresh page after SW skip waiting in case if controllerchange event wasn't fired
    refreshPageTimeout = setTimeout(() => {
      refreshPageAfterUpdate();
    }, 3000);
    // refresh page after SW skip waiting
    navigator.serviceWorker.addEventListener(
      'controllerchange',
      refreshPageAfterUpdate,
    );
  } else {
    //To prevent show update notification popup several times
    notificationPlugin.get().hideMessage();
  }
};

const isFeatureWindowVisible = ref(false);

const closeFeatureWindow = () => {
  isFeatureWindowVisible.value = false;
};

let showNotification: Cancellable;
const notificationData = {
  link: {
    text: 'Learn More',
    onClick: (event: Event) => {
      event.preventDefault();
      showNotification?.cancel();
      setChangeLogVisibility(true);
    },
  },
};

watch(changeLogHasContent, () => {
  const hasUpdated = route.query['version-updated'] === 'true';
  if (hasUpdated) {
    showNotification = notificationPlugin.get().show({
      text: $t('pages.login.app-success-updated.text'),
      type: NotificationType.Success,
      ...(changeLogHasContent.value && {
        data: notificationData,
      }),
    });
  }
});

const subscription: Ref<Subscription | null> = ref(null);

onMounted(async () => {
  subscription.value = useSecretToggle(
    versionNumber.value as HTMLElement,
    'click',
    () => (isFeatureWindowVisible.value = true),
    clickCount,
    timeToResetClickCounter,
  );

  window.onbeforeunload = () => {
    if (isPageRedirectedFromLogout.value) {
      sessionStoragePlugin.get().removeItem('logout');
    }
  };
  if (isPageRedirected.value) {
    if (isPageHasSameState.value) {
      if (validQuery?.code) {
        await requestTokenSet({
          code: validQuery.code,
        });
      }
    } else {
      notificationPlugin.get().show({
        text: $t('pages.login.something-went-wrong.text'),
        type: NotificationType.Info,
        duration: -1,
      });
    }
  }
});

onBeforeUnmount(() => {
  if (subscription.value) {
    subscription.value.unsubscribe();
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, {
      name: "animation",
      appear: !isPageRedirected.value
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h2", _hoisted_2, _toDisplayString(_unref(title)), 1),
          _createElementVNode("div", _hoisted_3, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "logo" }, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "ALDI logo",
                "data-e2e": "aldi-logo"
              })
            ], -1)),
            _createElementVNode("div", _hoisted_4, [
              (isPageRedirectedFromLogout.value)
                ? (_openBlock(), _createElementBlock("h1", _hoisted_5, _toDisplayString(_unref($t)('pages.login.logged-out.text')), 1))
                : (_openBlock(), _createElementBlock("h1", _hoisted_6, [
                    _createTextVNode(_toDisplayString(_unref($t)('pages.login.headline.text')), 1),
                    _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                    _createTextVNode(_toDisplayString(_unref($t)('pages.login.subline.text')), 1)
                  ])),
              _createElementVNode("div", {
                ref_key: "scrollTo",
                ref: scrollTo
              }, [
                _createElementVNode("form", _hoisted_7, [
                  (_unref(error))
                    ? (_openBlock(), _createBlock(_unref(Error), {
                        key: 0,
                        "error-message": _unref(error).description
                      }, null, 8, ["error-message"]))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(Button), {
                    "data-e2e": "login-button",
                    onClick: _withModifiers(handleLogin, ["stop","prevent"]),
                    disabled: _unref(loading),
                    loading: _unref(loading),
                    class: "login-button"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref($t)('pages.login.button-submit.text')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "loading"])
                ])
              ], 512),
              _createElementVNode("div", {
                class: "app-version",
                "data-e2e": `picking-app-version`,
                ref_key: "versionNumber",
                ref: versionNumber
              }, " v" + _toDisplayString(_unref(appVersion)), 513)
            ])
          ]),
          _createVNode(_unref(Popup), {
            visible: isFeatureWindowVisible.value,
            onClose: _cache[0] || (_cache[0] = () => closeFeatureWindow()),
            fullPageSize: "",
            withoutHeader: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(FeatureToggleSection))
            ]),
            _: 1
          }, 8, ["visible"])
        ])
      ]),
      _: 1
    }, 8, ["appear"]),
    _createVNode(_unref(Dialog), {
      visible: _unref(notify),
      "title-text": _unref($t)('pages.login.login-not-possible-dialog.title.text'),
      "content-text": _unref(notifyMessage),
      confirmText: _unref($t)('pages.login.login-not-possible-dialog.confirm.text'),
      onConfirmed: _unref(disableNotify),
      showOnlyConfirm: true
    }, null, 8, ["visible", "title-text", "content-text", "confirmText", "onConfirmed"]),
    _createVNode(_unref(Dialog), {
      visible: showReloadMessage.value,
      titleText: _unref($t)('pages.login.app-updated-dialog.title.text'),
      contentText: _unref($t)('pages.login.app-updated-dialog.content.text'),
      confirmText: _unref($t)('pages.login.app-updated-dialog.confirm.text'),
      showOnlyConfirm: true,
      onConfirmed: updateSW
    }, null, 8, ["visible", "titleText", "contentText", "confirmText"]),
    _createVNode(_unref(Dialog), {
      visible: showChangeLog.value,
      titleText: _unref(changeLogTitleText),
      confirmText: _unref($t)('pages.login.change-log-dialog.confirm.text'),
      showOnlyConfirm: true,
      onConfirmed: _cache[1] || (_cache[1] = () => setChangeLogVisibility(false))
    }, {
      default: _withCtx(() => [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(changeLogContent), (text, key) => {
            return (_openBlock(), _createElementBlock("li", { key: key }, _toDisplayString(text), 1))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["visible", "titleText", "confirmText"])
  ], 64))
}
}

})