import type { OrderMetadataService } from '@/features/orderMetadata';
import type { PipelinePlugin } from '@ads/plugin-pipeline';

interface RemoveOrderMetadataByIdsPluginDTO {
  ids: string[];
}

/**
 * Remove order metadata with specific IDs
 */
export class RemoveOrderMetadataByIdsPlugin<
  T extends RemoveOrderMetadataByIdsPluginDTO,
> implements PipelinePlugin<T>
{
  constructor(private orderMetadataService: OrderMetadataService) {}

  public async execute(dataTransferObject: T): Promise<T> {
    await this.orderMetadataService.removeByIds(dataTransferObject.ids);
    return dataTransferObject;
  }
}
