import type { ProcessedOrderServiceInterface } from '@/features/processed-order';
import type { PipelinePlugin } from '@ads/plugin-pipeline';

interface RemoveProcessedOrdersByIdsPluginDTO {
  ids: string[];
}

/**
 * Remove processed orders by ids
 */

export class RemoveProcessedOrdersByIdsPlugin<
  T extends RemoveProcessedOrdersByIdsPluginDTO,
> implements PipelinePlugin<T>
{
  constructor(private processedOrderService: ProcessedOrderServiceInterface) {}

  public async execute(dataTransferObject: T): Promise<T> {
    await this.processedOrderService.removeByIds(dataTransferObject.ids);
    return dataTransferObject;
  }
}
