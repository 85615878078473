
import type { PropType, Ref } from 'vue';
import { toRefs } from 'vue';
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import { StorageSortDirection } from '@/features/core/storage';
import type { BasicTemperatureClass } from '@/features/products';
import { basicTemperatureClasses, ProductIcons } from '@/features/products';
import type { SortTypes, SortTypeValue } from '@/features/ui';
import {
  Button,
  Checkbox,
  CheckboxToggler,
  FilterIcon,
  Popup,
  RadioGroup,
  sortTypes,
} from '@/features/ui';
import { cloneItem } from '@/utils/helpers/clone';
import { useCheckInOrders } from '../composables';

export default defineComponent({
  name: 'OrderFilter',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    btnOutline: {
      type: Boolean,
      default: false,
    },
    typesOfItem: {
      type: Object as PropType<BasicTemperatureClass[]>,
      default: basicTemperatureClasses,
    },
    filterSorting: {
      default: null,
      type: Object as PropType<SortTypeValue>,
    },
    isBannerActive: {
      type: Boolean,
      default: false,
    },
    isPickingInProgressOrdersVisible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FilterIcon,
    Popup,
    RadioGroup,
    Checkbox,
    Button,
    ProductIcons,
    CheckboxToggler,
  },
  setup(props, { emit }) {
    const visibleFilter = ref(false);
    const { isPickingInProgressOrdersVisible, typesOfItem } = toRefs(props);
    const pickingInProgressOrdersVisible: Ref<boolean> = ref(
      isPickingInProgressOrdersVisible.value,
    );

    const temperatureClassList = ref(
      cloneItem<BasicTemperatureClass>(typesOfItem.value),
    );

    const temperatureClassesCount = computed(
      () =>
        temperatureClassList.value.filter(
          (product: BasicTemperatureClass) => product.active,
        ).length,
    );

    const listItem = ref<string[]>([]);

    const sortedlist: SortTypes[] = reactive(sortTypes);

    const sortBy = ref(
      sortedlist?.find(
        (item) =>
          item.value?.sortBy === props.filterSorting?.sortBy &&
          item.value?.sort === props.filterSorting?.sort,
      )?.value,
    );

    const updateInput = function (select: SortTypeValue) {
      sortBy.value = select;
    };

    const { checkInBanner } = useCheckInOrders();

    const toggleShowFilter = () => {
      if (visibleFilter.value) {
        emit(
          'changeFilter',
          listItem.value,
          sortBy.value?.sortBy,
          sortBy.value?.sort,
          pickingInProgressOrdersVisible.value,
        );
      }
      checkInBanner.value = visibleFilter.value;
      visibleFilter.value = !visibleFilter.value;
    };

    watch(temperatureClassesCount, () => {
      listItem.value = [];
      const selectedProdType = temperatureClassList.value.filter(
        (product: BasicTemperatureClass) => product.active,
      );
      if (selectedProdType.length > 0) {
        sortBy.value = {
          sortBy: 'itemsCount.total',
          sort: StorageSortDirection.DESC,
        };
        selectedProdType.forEach((product: BasicTemperatureClass) => {
          if (product.active) {
            listItem.value.push(product.name);
          }
        });
      } else {
        sortBy.value = sortedlist[0].value;
      }
    });

    function clearFilter(): void {
      temperatureClassList.value.forEach((item: BasicTemperatureClass) => {
        item.active = false;
      });
    }

    return {
      props,
      visibleFilter,
      toggleShowFilter,
      temperatureClassList,
      sortedlist,
      sortBy,
      updateInput,
      temperatureClassesCount,
      clearFilter,
      pickingInProgressOrdersVisible,
    };
  },
});
