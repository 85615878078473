
import type { PropType } from 'vue';
import { computed, defineComponent, ref, toRef } from 'vue';
import { useProduct } from '@/features/picking/composables';
import { MinusIcon, PlusIcon, CheckIcon, EditIcon } from '@/features/ui/icons';
import { Button } from '@/features/ui/components';
import { TabVariants } from '@/features/ui/types';
import { ReplacementIcon } from '@/features/ui/icons';
import CounterBox from '@/features/ui/components/CounterBox.vue';
import type { OrderItem } from '@/features/orders/types';
import { OrderItemStatus } from '@/features/orders/types';

export default defineComponent({
  name: 'ProductCardFooterDefault',
  props: {
    orderItem: {
      type: Object as PropType<OrderItem>,
      default: () => ({}),
    },
    currentTab: String,
    hasReplacement: {
      type: Boolean,
      default: false,
    },
    showCustomerNote: {
      type: Boolean,
      default: false,
    },
    isManualVerificationEnabled: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'decrease',
    'increase',
    'submit',
    'edit',
    'replace',
    'handle-replace',
    'validateItem',
  ],
  components: {
    CounterBox,
    EditIcon,
    MinusIcon,
    PlusIcon,
    CheckIcon,
    Button,
    ReplacementIcon,
  },
  setup(props, { emit }) {
    const orderItemRef = toRef(props, 'orderItem');
    const { canDecrease, canIncrease, isFilled } = useProduct(orderItemRef);

    const isAlertDisplay = ref(false);

    const onSubmit = () => {
      if (!isFilled.value) {
        isAlertDisplay.value = true;
      } else {
        isAlertDisplay.value = false;
        emit('submit', props.orderItem.id);
      }
    };

    const onEdit = () => {
      emit('edit', props.orderItem.id);
    };

    const onHandleReplace = () => {
      emit('handle-replace', props.orderItem);
    };

    const onUnableToScanBarcode = () => {
      emit('validateItem', props.orderItem);
    };

    const isActivePickedButton = computed(
      () =>
        props.orderItem.status === OrderItemStatus.picked &&
        props.currentTab !== TabVariants.Changed,
    );

    const setAlertDisplayState = (value: boolean) => {
      isAlertDisplay.value = value;
    };

    const completePick = () => {
      setAlertDisplayState(false);
      emit('submit', props.orderItem.id);
    };

    const sku = computed<string>(() => {
      return props.orderItem.product
        ? props.orderItem.product.sku
        : props.orderItem.id;
    });

    const onReplace = () => {
      emit('replace', props.orderItem);
    };

    const isTypeZeroItem = (item: OrderItem) => {
      return item.product?.rwpType === 0;
    };

    const showIncreaseButton = computed(() => {
      return (
        !props.isManualVerificationEnabled ||
        props.orderItem?.isBarcodeValidated ||
        !isTypeZeroItem(props.orderItem)
      );
    });

    const showUnableToScanBarcodeButton = computed(
      () =>
        props.isManualVerificationEnabled &&
        props.orderItem.status !== 'picked' &&
        props.orderItem.product?.rwpType === 0,
    );

    return {
      completePick,
      setAlertDisplayState,
      isAlertDisplay,
      onSubmit,
      onEdit,
      canDecrease,
      canIncrease,
      isActivePickedButton,
      sku,
      onReplace,
      onHandleReplace,
      TabVariants,
      showIncreaseButton,
      onUnableToScanBarcode,
      showUnableToScanBarcodeButton,
    };
  },
});
