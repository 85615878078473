import type { LoggerService } from '@/features/core/logger/services/logger-service';
import { ServiceWorkerEventTypes } from '@/features/service-worker/types';
import { jsonParseIfString } from '@/utils/helpers/jsonParseIfString';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type {
  OrderItemPushEventRecords,
  PushNotificationDTO,
  OrderItemPushEventTimestampRecords,
} from '../types';
import { OrderItemPushEvents } from '../types';

/**
 * Normalize and store initial data to DTO
 */

export class NormalizeEventDataPlugin
  implements PipelinePlugin<PushNotificationDTO>
{
  constructor(private loggerService: LoggerService) {}
  public execute(dataTransferObject: PushNotificationDTO): PushNotificationDTO {
    const eventData = dataTransferObject.eventData;

    dataTransferObject.rawEntityType = eventData.entity;
    dataTransferObject.ids = this.getPushEventIds(
      eventData.ids as string,
      eventData.action,
    );
    dataTransferObject.action = eventData.action;
    dataTransferObject.fcmMessageId = eventData.fcmMessageId;
    dataTransferObject.eventType = dataTransferObject.eventData.syncTag
      ? ServiceWorkerEventTypes.Sync
      : ServiceWorkerEventTypes.Push;
    dataTransferObject.eventNames = this.getPushEventNames(
      eventData.eventNames,
    );
    dataTransferObject.eventTimestamps = this.getPushEventTimestamps(
      eventData.eventTimestamps,
    );
    dataTransferObject.notificationDelay = this.getNotificationDelay(
      dataTransferObject.eventData.requestTimestamp,
      dataTransferObject.eventData.receivedTimestamp,
    );

    return dataTransferObject;
  }

  private getPushEventIds(ids: string | undefined, action: string | undefined) {
    if (!ids) {
      return [];
    }
    try {
      return (jsonParseIfString(ids) as number[]).map(String); // NOTE: type assertion is needed because 'JSON.parse' returns 'any'
    } catch (error) {
      this.loggerService.error(
        `Push handling (${String(action)}): error parsing 'ids' (${ids})`,
        error,
      );
    }
    return [];
  }

  private getPushEventNames(
    eventNames: string | undefined,
  ): OrderItemPushEventRecords {
    if (eventNames === undefined) {
      return {};
    }
    try {
      return this.parsePushEventNames(eventNames);
    } catch (error) {
      this.loggerService.error(
        `Push handling: error parsing 'eventNames' (${eventNames})`,
        error,
      );
    }
    return {};
  }

  private parsePushEventNames(value: unknown): OrderItemPushEventRecords {
    const parsedValue = jsonParseIfString(value);

    if (!this.isOrderItemPushEventRecords(parsedValue)) {
      throw new Error('value is not a valid OrderItemPushEventRecords');
    }

    return parsedValue;
  }

  private isOrderItemPushEventRecords(
    value: unknown,
  ): value is OrderItemPushEventRecords {
    const pushEventsSet = new Set(Object.values(OrderItemPushEvents));

    return (
      typeof value === 'object' &&
      !(value instanceof Array) &&
      value !== null &&
      Object.values(value).every(
        (orderItemPushEvent) =>
          pushEventsSet.has(orderItemPushEvent) ||
          typeof orderItemPushEvent === 'string',
      )
    );
  }

  private getPushEventTimestamps(
    eventNames: string | undefined,
  ): OrderItemPushEventTimestampRecords {
    if (eventNames === undefined) {
      return {};
    }
    try {
      return this.parsePushEventTimestamps(eventNames);
    } catch (error) {
      this.loggerService.error(
        `Push handling: error parsing 'eventTimestamps' (${eventNames})`,
        error,
      );
    }
    return {};
  }

  private parsePushEventTimestamps(
    value: unknown,
  ): OrderItemPushEventTimestampRecords {
    const parsedValue = jsonParseIfString(value);

    if (!this.isOrderItemPushEventTimestampRecords(parsedValue)) {
      throw new Error(
        'value is not a valid OrderItemPushEventTimestampRecords',
      );
    }

    return parsedValue;
  }

  private isOrderItemPushEventTimestampRecords(
    value: unknown,
  ): value is OrderItemPushEventTimestampRecords {
    return (
      typeof value === 'object' &&
      !(value instanceof Array) &&
      value !== null &&
      Object.values(value).every((timestamp) => typeof timestamp === 'string')
    );
  }

  private getNotificationDelay(
    requestTimestamp: string | undefined,
    receivedTimestamp: string,
  ): string {
    if (!requestTimestamp) {
      return 'No request timestamp found';
    }
    const timeDifferenceInMilliseconds =
      new Date(receivedTimestamp).getTime() -
      new Date(requestTimestamp).getTime();
    return `${(timeDifferenceInMilliseconds / 1000).toFixed()}s`;
  }
}
