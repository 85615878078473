import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives } from "vue"

import { ItemsGroup } from '@/features/ui/components';
import { useOrdersOverview, OrderCard } from '@/features/orders';
import { toRefs } from 'vue';
import { $t } from '@/i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckedInOrderList',
  props: {
  disabledProcess: {
    type: Boolean,
    default: false,
  },
  checkedInOrders: {
    type: Array,
    default: () => [],
  },
},
  setup(__props) {

const props = __props;

const { disabledProcess, checkedInOrders } = toRefs(props);

const { startOrder, setCardsRefs, toggleNote } = useOrdersOverview();

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createBlock(_unref(ItemsGroup), {
    title: `${_unref($t)('pages.order-overview.checkin-title.text')} (${
      _unref(checkedInOrders).length
    })`,
    "data-e2e": "checked-in-orders-list-title"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(checkedInOrders), (order) => {
        return (_openBlock(), _createBlock(_unref(OrderCard), {
          key: order.id,
          ref_for: true,
          ref: (el) => _unref(setCardsRefs)(el, order.id),
          card: order,
          disabledProcess: _unref(disabledProcess),
          onGoNext: ($event: any) => (_unref(startOrder)(order)),
          onInfoClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(toggleNote)($event)))
        }, null, 8, ["card", "disabledProcess", "onGoNext"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["title"])), [
    [_vShow, _unref(checkedInOrders).length]
  ])
}
}

})