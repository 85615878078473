import { ExecPluginFactory, MultiPluginFactory } from '@/features/core/plugin';
import { syncSchedulerServicePlugin } from '@/features/sync-scheduler';
import { ordersApiPlugin, ordersApiRegistryPlugin } from './api';
import { ordersEntityPlugin } from './entities';
import { ordersServicePlugin } from './services';
import { orderCheckinNotifyPlugin } from './checkin';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import { RedirectFromDeletedOrderEvent } from './events';
import { redirectIfOrderRoute } from '@/utils/helpers/redirectIfOrderRoute';
import {
  NotificationType,
  notificationPlugin,
} from '@/features/core/notifications';
import { loggerServicePlugin } from '@/features/core/logger';

const ordersExecutePlugin = ExecPluginFactory.fn(() => {
  const orderService = ordersServicePlugin.get();
  syncSchedulerServicePlugin
    .get()
    .onScheduleDone(orderService.orderScheduleCallback.bind(orderService));

  eventBusServicePlugin
    .get()
    .on(RedirectFromDeletedOrderEvent, ({ orderId }) => {
      const isOrderRoute = redirectIfOrderRoute(
        orderId,
        '/',
        eventBusServicePlugin.get(),
      );
      if (!isOrderRoute) {
        return;
      }
      loggerServicePlugin.get().info('Redirecting from deleted order');
      notificationPlugin.get().show({
        text: 'This order has already been handed over',
        type: NotificationType.Info,
      });
    });
});

export const ordersPlugin = MultiPluginFactory.with({
  ordersApiPlugin,
  ordersApiRegistryPlugin,
  ordersEntityPlugin,
  ordersServicePlugin,
  orderCheckinNotifyPlugin,
  ordersExecutePlugin,
});
