import type { Ref } from 'vue';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { appCrashServicePlugin } from '@/features/app-crash';
import { getParamFromHashtagUrl, scrollToOrder } from '@/features/orders';

import type { OrderCard, UseRedirectedGuard } from '@/features/orders';

export function useRedirectedGuard(): UseRedirectedGuard<typeof OrderCard> {
  const redirectGuard = async (next: NavigationGuardNext): Promise<void> => {
    const pluginSuccesses = [
      await appCrashServicePlugin.get().checkUnfinishedProcessAndRedirect(),
    ];
    let nextCalled = false;
    for (const isSuccess of pluginSuccesses) {
      if (!nextCalled) {
        next(!isSuccess);
        nextCalled = true;
      }
    }
    if (!nextCalled) {
      next();
    }
  };

  const handleRoute = (
    route: RouteLocationNormalized,
    cardsRefs: Ref<{ [key: string]: typeof OrderCard }>,
  ): Promise<string | null> => {
    return new Promise((resolve) => {
      const orderId = getParamFromHashtagUrl(route, 'id');

      if (!orderId) {
        return resolve(null);
      }

      const card = cardsRefs.value[`card-${String(orderId)}`];

      //If route is on picking tab, then scroll to the related order
      if (route.hash.startsWith('#picking?id=') && card) {
        void scrollToOrder(card);
        return resolve(orderId);
      }

      if (route.hash.startsWith('#handover?id=') && card) {
        return resolve(orderId);
      }

      return resolve(null);
    });
  };

  return {
    redirectGuard,
    handleRoute,
  };
}
