import type { EventBus } from '@/features/core/event-bus/types';
import { ChangeUrlEvent } from '@/features/route-leave-guard/events';
import { getOrderFromWindow } from '@/utils/helpers/getOrderFromWindow';

export const redirectIfOrderRoute = (
  orderId: string,
  targetRoutePath: string,
  eventBus: EventBus,
): boolean => {
  const currentOrder = getOrderFromWindow(window.location);

  const isCurrentRouteEqual = orderId === currentOrder;
  if (isCurrentRouteEqual) {
    eventBus.emit(
      new ChangeUrlEvent(targetRoutePath, { bypassRouteNavigationGuard: true }),
    );
  }
  return isCurrentRouteEqual;
};
