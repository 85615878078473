import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

import { $t } from '@/i18n';
import { ItemsGroup } from '@/features/ui/components';
import { useOrdersOverview, OrderCard } from '@/features/orders';
import { toRefs } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InProgressOrderList',
  props: {
  disabledProcess: {
    type: Boolean,
    default: false,
  },
  pickingInProgressOrders: {
    type: Array,
    default: () => [],
  },
  isPickingInProgressOrdersVisible: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const {
  disabledProcess,
  pickingInProgressOrders,
  isPickingInProgressOrdersVisible,
} = toRefs(props);

const { startOrder, setCardsRefs, toggleNote } = useOrdersOverview();

return (_ctx: any,_cache: any) => {
  return (_unref(isPickingInProgressOrdersVisible) && _unref(pickingInProgressOrders).length)
    ? (_openBlock(), _createBlock(_unref(ItemsGroup), {
        key: 0,
        title: `${_unref($t)('pages.order-overview.picking-in-progress-title.text')} (${
      _unref(pickingInProgressOrders).length
    })`,
        "data-e2e": "orders-in-progress-list-title"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pickingInProgressOrders), (order) => {
            return (_openBlock(), _createBlock(_unref(OrderCard), {
              key: order.id,
              ref_for: true,
              ref: (el) => _unref(setCardsRefs)(el, order.id),
              card: order,
              disabledProcess: _unref(disabledProcess),
              onGoNext: ($event: any) => (_unref(startOrder)(order)),
              onInfoClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(toggleNote)($event)))
            }, null, 8, ["card", "disabledProcess", "onGoNext"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}
}

})