import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 2 }
const _hoisted_2 = { class: "ut-font_fix" }

import type { Ref } from 'vue';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { $t } from '@/i18n';
import {
  BarcodeNotFoundInOrderError,
  ProductPickingCompletedError,
  ValidatePickingError,
} from '@/features/barcode/errors';
import { barcodeServicePlugin, vueBarcodePlugin } from '@/features/barcode';
import {
  deviceFeedbackServicePlugin,
  playlistActionRequiredBarcodeScan,
  playlistSuccessBarcodeScan,
  playlistSuccessBarcodeScanThenActionRequired,
} from '@/features/device-feedback';
import { errorPlugin } from '@/features/core/errors';
import type { OrderItem } from '@/features/orders';
import { OrderEventNames, OrderItemStatus } from '@/features/orders';
import { ordersServicePlugin } from '@/features/orders';
import {
  useAggregateOrderItems,
  useCheckInOrders,
} from '@/features/orders/composables';
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';
import {
  ProductCard,
  ProductCardFooterDefault,
  ProductCardFooterWithWeight,
  ReplacementBlock,
  ReplacementCard,
  useOrder,
  ValidateItemBarcodePopup,
} from '@/features/picking';
import { useItemManualVerification } from '@/features/picking/composables/useItemManualVerification';
import {
  AppHeader,
  Button,
  EmptySection,
  pickingFilters,
  Popup,
  ScreenLoading,
  StatusBar,
  TabVariants,
  WeightPopup,
} from '@/features/ui';
import RowFilter from '@/features/ui/components/RowFilter.vue';
import { useWeightList } from '@/features/ui/composables';

const beforeScrollDelay = 500;

export default /*@__PURE__*/_defineComponent({
  __name: 'PickingOrder',
  props: {
  id: { type: String, required: true },
  tab: { type: String, default: '' },
},
  setup(__props) {

/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access */
const props = __props;

const {
  order,
  ordersOnTab,
  onIncrease,
  onDecrease,
  loadOrder,
  onSubmit,
  onEdit,
  onEditWeight,
  onChangeTab,
  currentTab,
  selectedId,
  onChangeWeightBlock,
  visibleWeightPopup,
  isAllItemsPicked,
  filterQuantity,
  loading,
  processing,
  openWeightPopup,
  interruptOngoingSavings,
  redirectIfOrderNotPicking,
} = useOrder();

const { startTracking, stopTracking, cancelTracking } = usePerformanceTracker();

const { isBarcodeValidating } = useWeightList();
const scannedBarcode = ref('');

const { aggregatedOrderItems } = useAggregateOrderItems(ordersOnTab);
const products = ref<Record<string, InstanceType<typeof ProductCard>>>({});
const applyToProductRef = (sku: string) => {
  return (element?: InstanceType<typeof ProductCard>) => {
    if (element) {
      products.value[sku] = element;
    }
  };
};

const {
  loadItemPickingConfiguration,
  isItemManualVerificationEnabled,
  fallbackVerificationPin,
} = useItemManualVerification();

const toEditWeightDelay = beforeScrollDelay + 500;

const scrollToProduct = (sku: string) => {
  startTracking(
    `scroll-to-product-${sku}`,
    PerformanceThresholdEnum.SMALL_OPERATION,
  );
  setTimeout(() => {
    const product = products.value[sku];

    if (!product) {
      return;
    }

    (product.$el as Element).scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });

    product.highlightCard();
  }, beforeScrollDelay);

  stopTracking(`scroll-to-product-${sku}`);
};

const decreaseOrderItemQuantity = (item: OrderItem) => {
  const product = products.value[item.product.sku];
  onDecrease(item.product.sku, false, true);
  product.highlightCard();
};

const increaseOrderItemQuantity = (item: OrderItem) => {
  const product = products.value[item.product.sku];
  onIncrease(item.product.sku, false, true);
  product.highlightCard();
};

const completeOrderItemQuantity = (item: OrderItem) => {
  const product = products.value[item.product.sku];
  product.showCompletedOverlay(() => onSubmit(item));
  trackItemPickedEvent();
};

const closeWeightBlock = (sku = '') => {
  scannedBarcode.value = '';
  visibleWeightPopup.value = false;
  if (sku) {
    scrollToProduct(sku);
  }
};

const onEditWeightBlock = async (selectedItem: OrderItem) => {
  if (selectedItem.status === OrderItemStatus.picked) {
    trackItemPickedEvent();
  }

  await onChangeWeightBlock(selectedItem);
  closeWeightBlock();
};

const setDefaultTab = () => {
  let defaultTab: string = TabVariants.NotPicked;
  if (props.tab) {
    defaultTab = props.tab;
  } else if (isAllItemsPicked?.value) {
    defaultTab = TabVariants.Picked;
  }
  void onChangeTab(defaultTab);
};

onMounted(async () => {
  startTracking(
    `picking-order-${props.id}`,
    PerformanceThresholdEnum.PAGE_LOAD,
  );
  await loadOrder(props.id);
  stopTracking(`picking-order-${props.id}`);
  await redirectIfOrderNotPicking(order.value);
  await loadItemPickingConfiguration();
  setDefaultTab();
});

const barcodeService = vueBarcodePlugin.get();

barcodeService.onBarcode({
  async next(barcode: string) {
    startTracking(
      `barcode-scan-${barcode}`,
      PerformanceThresholdEnum.SMALL_OPERATION,
    );

    if (visibleWeightPopup.value) {
      cancelTracking(`barcode-scan-${barcode}`);

      return;
    }

    if (!order.value) {
      cancelTracking(`barcode-scan-${barcode}`);

      return;
    }

    const barcodeItem = await barcodeServicePlugin
      .get()
      .findItemByBarcode(order.value.items, barcode);

    if (!barcodeItem.item) {
      errorPlugin
        .get()
        .handle(new BarcodeNotFoundInOrderError({ barcodeValue: barcode }));

      await deviceFeedbackServicePlugin
        .get()
        .trigger(playlistSuccessBarcodeScanThenActionRequired);

      stopTracking(`barcode-scan-${barcode}`);

      return;
    }

    const orderItemProductSku = barcodeItem.item.product.sku;

    //If the order item (not RWT) quantity has been already completed
    if (
      barcodeItem.item.status === OrderItemStatus.picked &&
      barcodeItem.item.product.rwpType !== 1
    ) {
      errorPlugin.get().handle(new ProductPickingCompletedError());

      await deviceFeedbackServicePlugin
        .get()
        .trigger(playlistActionRequiredBarcodeScan);

      stopTracking(`barcode-scan-${barcode}`);

      return;
    }

    if (barcodeItem.item.product.rwpType) {
      if (currentTab.value === TabVariants.NotPicked) {
        scrollToProduct(orderItemProductSku);
      }

      scannedBarcode.value = barcode;
      if (currentTab.value === TabVariants.NotPicked) {
        setTimeout(() => {
          if (barcodeItem.item && barcodeItem.item.id) {
            openWeightPopup(barcodeItem.item.id);
          }
        }, toEditWeightDelay);
      } else {
        if (barcodeItem.item && barcodeItem.item.id) {
          openWeightPopup(barcodeItem.item.id);
        }
      }

      stopTracking(`barcode-scan-${barcode}`);

      return;
    }

    onIncrease(orderItemProductSku, false, true);

    if (barcodeItem.item.quantity === barcodeItem.item.quantityOriginal) {
      const product = products.value[orderItemProductSku];
      product.showCompletedOverlay(() => onSubmit(barcodeItem.item));

      trackItemPickedEvent();
    }

    scrollToProduct(orderItemProductSku);
    await deviceFeedbackServicePlugin.get().trigger(playlistSuccessBarcodeScan);

    stopTracking(`barcode-scan-${barcode}`);
  },
});

barcodeService.onBarcode({
  async next() {
    if (!visibleWeightPopup.value) {
      errorPlugin.get().handle(new ValidatePickingError());
      await deviceFeedbackServicePlugin
        .get()
        .trigger(playlistSuccessBarcodeScanThenActionRequired);
    }
  },
  pattern: 'ScaleRegexp',
});

const router = useRouter();

const useWeightCard = computed(() => (rwtType: number | undefined) => {
  return rwtType && rwtType !== 0;
});

const goToReviewOrder = () => {
  startTracking(
    `picking-order-to-review-order-${props.id}`,
    PerformanceThresholdEnum.ROUTE_CHANGE,
  );

  processing.value = true;
  void router.push({
    name: 'review-order',
    params: { id: props.id },
  });
};

const replacementCandidate = ref<OrderItem | null>(null);
const isReplacementVisible = ref<boolean>(false);
const { checkInBanner, showBanner } = useCheckInOrders();
const hideCheckinOnKeyboardOpen = (isKeyboardOpen: boolean) => {
  checkInBanner.value = !isKeyboardOpen;
};
const setReplacementVisibility = (visible = false) => {
  isReplacementVisible.value = visible;
  checkInBanner.value = !visible;
  return isReplacementVisible.value;
};
const triggerReplacementFlow = (orderItem: OrderItem) => {
  replacementCandidate.value = orderItem;
  currentOrderItem.value = orderItem;
  setReplacementVisibility(true);
};

const currentOrderItem = ref<OrderItem | null>(null);
const replacementTitle = computed((): string => {
  if (!currentOrderItem.value) {
    return '';
  }

  if (currentOrderItem.value.isReplaceable) {
    return currentOrderItem.value.quantity
      ? $t('pages.picking-order.replacement-title.items-picked.text', {
          quantity: currentOrderItem.value.quantity,
          quantityOriginal: currentOrderItem.value.quantityOriginal,
        })
      : '';
  }

  return $t('pages.picking-order.replacement-title.cannot-replace.text', {
    quantity: currentOrderItem.value.quantity,
    quantityOriginal: currentOrderItem.value.quantityOriginal,
  });
});

const handleNoReplacement = () => {
  if (currentOrderItem.value) {
    const product: ProductCard =
      products.value[currentOrderItem.value.product.sku];

    setReplacementVisibility(false);

    product.showCompletedOverlay(() => {
      void onSubmit(currentOrderItem.value);
    });

    trackItemPickedEvent();
  }
};

const handleReplace = async (item: OrderItem) => {
  if (item) {
    currentOrderItem.value = item;
  }

  void interruptOngoingSavings();

  if (order.value && currentOrderItem.value) {
    startTracking(
      `picking-order-replacement-${order.value.id}-${currentOrderItem.value.id}`,
      PerformanceThresholdEnum.SCREEN_TRANSITION,
    );
    await ordersServicePlugin.get().saveOrder(order.value);
    await router.push({
      name: 'replacement',
      params: {
        orderId: order.value.id,
        orderItemId: currentOrderItem.value.id,
      },
    });
  }
};

const isValidateBarcodePopupVisible = ref(false);
const validateBarcodePopupTitle = ref('');
let barcodeValidationItem: OrderItem | null = null;
const validateBarcodePopup: Ref<null | typeof ValidateItemBarcodePopup> =
  ref(null);
const onUnableToScanBarcode = (item: OrderItem) => {
  barcodeValidationItem = item;
  validateBarcodePopupTitle.value = item.product.productName;
  toggleValidateBarcodePopup();
};
const toggleValidateBarcodePopup = () => {
  isValidateBarcodePopupVisible.value = !isValidateBarcodePopupVisible.value;
};
const indicateValidation = (isValid: boolean) => {
  if (validateBarcodePopup.value) {
    validateBarcodePopup.value.indicateValidation(isValid);
  }
};
const isBarcodeValid = (barcodes: string[], code: string): boolean => {
  if (code === fallbackVerificationPin.value) {
    return true;
  }
  const validBarcodes = barcodes.find((barcode) => barcode.slice(-4) === code);
  return Boolean(validBarcodes);
};
const validateBarcode = (code: string) => {
  const productBarcodes = barcodeValidationItem?.product.barcodes || [];
  const isValid = isBarcodeValid(productBarcodes, code);
  if (isValid && barcodeValidationItem) {
    barcodeValidationItem.isBarcodeValidated = true;
    setTimeout(() => {
      toggleValidateBarcodePopup();
    }, 300);
  }
  indicateValidation(isValid);
};

onBeforeUnmount(() => {
  checkInBanner.value = true;
});

const onEditWeightCard = (orderItem: OrderItem) => {
  onEditWeight(orderItem.id, scannedBarcode.value);
  if (currentTab.value === TabVariants.NotPicked) {
    return;
  }
  currentTab.value = TabVariants.NotPicked;
  scrollToProduct(orderItem.product.sku);
  setTimeout(() => {
    onEditWeight(orderItem.id, scannedBarcode.value);
    scannedBarcode.value = '';
  }, toEditWeightDelay);
};

const onEditCard = (orderItem: OrderItem) => {
  onEdit(orderItem.id);

  currentTab.value = TabVariants.NotPicked;
  scrollToProduct(orderItem.product.sku);
};

const isScreenLoadingVisible = computed(() => {
  return loading.value || processing.value || isBarcodeValidating.value;
});

const screenLoadingTitle = computed(() => {
  if (isBarcodeValidating.value) {
    return $t('pages.picking-order.barcode-validating.title');
  }
  return $t('pages.picking-order.order-saved.title');
});

const trackItemPickedEvent = () => {
  if (!order.value) {
    return;
  }
  void ordersServicePlugin
    .get()
    .trackEvent(order.value, OrderEventNames.item_handling_completed, {
      allowMultipleTracking: true,
      skipSaving: true,
    });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(order))
      ? (_openBlock(), _createBlock(_unref(AppHeader), {
          key: 0,
          fixed: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(StatusBar), {
              customerNote: _unref(order)?.cartNote,
              hasBackButton: false,
              order: _unref(order),
              to: ""
            }, null, 8, ["customerNote", "order"]),
            _createVNode(RowFilter, {
              "current-tab": _unref(currentTab),
              filterQuantity: _unref(filterQuantity),
              filters: _unref(pickingFilters),
              onChangeTab: _unref(onChangeTab)
            }, null, 8, ["current-tab", "filterQuantity", "filters", "onChangeTab"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (isScreenLoadingVisible.value)
      ? (_openBlock(), _createBlock(_unref(ScreenLoading), {
          key: 1,
          title: screenLoadingTitle.value,
          blur: "",
          inFront: ""
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    (_unref(aggregatedOrderItems))
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass([{ 'list--with-footer': _unref(isAllItemsPicked) }, "container list"])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(aggregatedOrderItems), (item) => {
            return (_openBlock(), _createBlock(_unref(ProductCard), {
              key: item.id,
              ref_for: true,
              ref: applyToProductRef(item.product.sku),
              "current-tab": _unref(currentTab),
              "order-item": item
            }, {
              footer: _withCtx(() => [
                (!item.product.rwpType)
                  ? (_openBlock(), _createBlock(_unref(ProductCardFooterDefault), {
                      key: 0,
                      "current-tab": _unref(currentTab),
                      "has-replacement": Boolean(item.replacements?.length),
                      showCustomerNote: _unref(currentTab) === _unref(TabVariants).NotPicked,
                      isManualVerificationEnabled: 
              _unref(isItemManualVerificationEnabled)(item.product.sku)
            ,
                      "order-item": item,
                      onDecrease: ($event: any) => (decreaseOrderItemQuantity(item)),
                      onEdit: () => onEditCard(item),
                      onIncrease: ($event: any) => (increaseOrderItemQuantity(item)),
                      onReplace: triggerReplacementFlow,
                      onSubmit: ($event: any) => (completeOrderItemQuantity(item)),
                      onValidateItem: _cache[0] || (_cache[0] = ($event: any) => (onUnableToScanBarcode($event))),
                      onHandleReplace: handleReplace
                    }, null, 8, ["current-tab", "has-replacement", "showCustomerNote", "isManualVerificationEnabled", "order-item", "onDecrease", "onEdit", "onIncrease", "onSubmit"]))
                  : (useWeightCard.value(item.product.rwpType))
                    ? (_openBlock(), _createBlock(_unref(ProductCardFooterWithWeight), {
                        key: 1,
                        "current-tab": _unref(currentTab),
                        "has-replacement": Boolean(item.replacements?.length),
                        isReplaceBtnExist: _unref(currentTab) === _unref(TabVariants).NotPicked,
                        showCustomerNote: _unref(currentTab) === _unref(TabVariants).NotPicked,
                        "order-item": item,
                        onEdit: () => onEditWeightCard(item),
                        onReplace: triggerReplacementFlow,
                        onMoveToNotFound: ($event: any) => (_unref(onSubmit)(item)),
                        onHandleReplace: handleReplace
                      }, null, 8, ["current-tab", "has-replacement", "isReplaceBtnExist", "showCustomerNote", "order-item", "onEdit", "onMoveToNotFound"]))
                    : _createCommentVNode("", true),
                (_unref(currentTab) === _unref(TabVariants).Changed)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.replacements, (replacement) => {
                        return (_openBlock(), _createBlock(_unref(ReplacementCard), {
                          key: replacement.id,
                          ref_for: true,
                          ref: applyToProductRef(replacement.product.sku),
                          isPicked: replacement.status === _unref(OrderItemStatus).picked,
                          "order-item": replacement
                        }, null, 8, ["isPicked", "order-item"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["current-tab", "order-item"]))
          }), 128)),
          (!_unref(aggregatedOrderItems).length && !_unref(loading))
            ? (_openBlock(), _createBlock(_unref(EmptySection), { key: 0 }))
            : _createCommentVNode("", true),
          (_unref(visibleWeightPopup) && _unref(order))
            ? (_openBlock(), _createBlock(_unref(WeightPopup), {
                key: 1,
                id: _unref(selectedId),
                orderItems: _unref(order).items,
                scannedBarcode: scannedBarcode.value,
                showScannedBarcodePreview: true,
                onClose: closeWeightBlock,
                onOnEditWeightBlock: onEditWeightBlock
              }, null, 8, ["id", "orderItems", "scannedBarcode"]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_unref(Popup), {
      isCloseButtonExist: false,
      visible: isReplacementVisible.value,
      bottom: "",
      class: "replacement-footer",
      onClose: setReplacementVisibility
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(ReplacementBlock), {
          isReplaceable: currentOrderItem.value?.isReplaceable,
          title: replacementTitle.value,
          onCancel: setReplacementVisibility,
          onReplace: handleReplace,
          onNoReplacement: handleNoReplacement
        }, null, 8, ["isReplaceable", "title"])
      ]),
      _: 1
    }, 8, ["visible"]),
    (_unref(isAllItemsPicked) && !_unref(loading))
      ? (_openBlock(), _createElementBlock("footer", {
          key: 3,
          class: _normalizeClass([{ 'fixed-footer-content__footer--with-banner': _unref(showBanner) }, "footer fixed-footer-content__footer"])
        }, [
          _createVNode(_unref(Button), {
            disabled: _unref(processing),
            btnOutline: "",
            class: "column--full",
            "data-e2e": "continue-to-review-order-button",
            onClick: goToReviewOrder
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_unref($t)('pages.picking-order.review-picking-button.text')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_unref(ValidateItemBarcodePopup), {
      ref_key: "validateBarcodePopup",
      ref: validateBarcodePopup,
      isVisible: isValidateBarcodePopupVisible.value,
      title: validateBarcodePopupTitle.value,
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (hideCheckinOnKeyboardOpen(false))),
      onCancel: toggleValidateBarcodePopup,
      onFocus: _cache[2] || (_cache[2] = ($event: any) => (hideCheckinOnKeyboardOpen(true))),
      onValidate: validateBarcode
    }, null, 8, ["isVisible", "title"])
  ], 64))
}
}

})