import { computed, ref } from 'vue';
import type { Ref } from 'vue';
import { useOrders } from '@/features/orders/composables';

const isPickingInProgressOrdersVisible = ref(false);
const { orders: pickingInProgressOrders, loadOrders: loadOrdersInProgress } =
  useOrders();
export const useInProgressOrders = () => {
  const pickingInProgressOrdersVisible: Ref<boolean> = ref(false);

  const showInProgressOrders = computed(
    () =>
      pickingInProgressOrdersVisible.value &&
      !!pickingInProgressOrders.value.length,
  );

  const changeInProgressOrdersVisible = (visible = false) => {
    isPickingInProgressOrdersVisible.value = visible;
  };

  return {
    showInProgressOrders,
    pickingInProgressOrders,
    isPickingInProgressOrdersVisible,
    changeInProgressOrdersVisible,
    loadOrdersInProgress,
  };
};
