import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "popup__title" }
const _hoisted_2 = { class: "product-img" }
const _hoisted_3 = { class: "popup__footer" }

import type { ComputedRef } from 'vue';
import { computed, onBeforeUnmount, onMounted, ref, toRefs, watch } from 'vue';
import { $t } from '@/i18n';
import {
  Button,
  CheckIcon,
  Dialog,
  Image,
  Popup,
  WeightBlock,
  WeightList,
} from '@/features/ui';
import { cloneItem } from '@/utils/helpers/clone';
import { roundValue } from '@/utils/helpers/roundValue';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';
import {
  BarcodeNotFoundInOrderError,
  barcodeServicePlugin,
  vueBarcodePlugin,
} from '@/features/barcode';
import { errorPlugin } from '@/features/core/errors';
import { StatusTypes } from '../types';
import { useWeightPopup } from '../composables';
import type { OrderItem, OrderWeight } from '@/features/orders/types';
import {
  isOrderItemRandomWeight,
  isOrderItemReplacement,
  isOverReplacementThreshold,
} from '@/features/orders/helpers';
import { ItemUnit, OrderItemStatus } from '@/features/orders/types';
import { useCheckInOrders } from '@/features/orders/composables';
import { loggerServicePlugin } from '@/features/core/logger';
import {
  deviceFeedbackServicePlugin,
  playlistActionRequiredBarcodeScan,
  playlistSuccessBarcodeScan,
} from '@/features/device-feedback';
import { unitToDisplay } from '@/utils/helpers/unitPluralHandler';
import { useOverpickingThresholds } from '@/features/picking';

interface Events {
  (e: 'close'): void;

  (
    e: 'onEditWeightBlock',
    order: OrderItem,
    weightBefore: OrderWeight[],
    status?: StatusTypes,
  ): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'WeightPopup',
  props: {
    orderItems: {},
    barcodeSelectedItem: { default: null },
    selectedItem: {},
    id: {},
    editable: { type: Boolean, default: true },
    showOriginal: { type: Boolean, default: true },
    showRemainingWeight: { type: Boolean, default: true },
    scannedBarcode: { default: '' },
    showScannedBarcodePreview: { type: Boolean, default: false }
  },
  emits: ["close", "onEditWeightBlock"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const { editable } = toRefs(props);

const emit = __emit;

const { checkInBanner } = useCheckInOrders();
const scannedBarcode = ref(props.scannedBarcode);
const randomWeightOverweightPickingThreshold: ComputedRef<number> = computed(
  () =>
    typeof overpickingThresholdMultiplier.value === 'number'
      ? order.value.amountOriginal * overpickingThresholdMultiplier.value
      : 0,
);
const isOverpickingThresholdReached: ComputedRef<boolean> = computed(() =>
  typeof overpickingThresholdMultiplier.value === 'number'
    ? order.value.amount > randomWeightOverweightPickingThreshold.value
    : false,
);
const isReplacementThresholdReached: ComputedRef<boolean> = computed(() =>
  isReplacement.value
    ? order.value.weights.some((orderWeight) =>
        isOverReplacementThreshold(orderWeight.weight),
      )
    : false,
);

onMounted(() => {
  checkInBanner.value = false;
});

onBeforeUnmount(() => {
  checkInBanner.value = true;
});

const { orderItems, selectedItem } = toRefs(props);
const { order, visible, weightBefore, initialWeight } = useWeightPopup(
  orderItems,
  props.id,
  selectedItem.value?.status,
);

const dialogView = ref('submit');
const isAlertDisplay = ref<boolean>(false);
const toggleDialog = () => {
  isAlertDisplay.value = !isAlertDisplay.value;
};
const confirmDialog = () => {
  toggleDialog();

  if (!order?.value) {
    return;
  }

  if (dialogView.value === StatusTypes.Discard) {
    const items = cloneItem<OrderItem>(orderItems.value);
    order.value = items.filter((item: OrderItem) => item.id === props.id)[0];
    order.value.weights = weightBefore.value;
    emit(
      'onEditWeightBlock',
      order.value,
      weightBefore.value,
      StatusTypes.Discard,
    );
    return;
  }

  order.value.status = OrderItemStatus.picked;
  emit('onEditWeightBlock', order.value, weightBefore.value);
};

const cancelDialog = () => {
  isAlertDisplay.value = false;
};

const closeWeightBlock = () => {
  dialogView.value = StatusTypes.Discard;
  if (order?.value?.amount !== initialWeight.value) {
    isAlertDisplay.value = true;
  } else {
    emit('close');
  }
};

const totalWeightReached = ref(false);
const isReplacement: ComputedRef<boolean> = computed(() =>
  isOrderItemReplacement(order.value),
);
const overpickingThreshold = useOverpickingThresholds();
const overpickingThresholdMultiplier = computed((): number | undefined => {
  if (isReplacement.value) {
    // Disable the threshold for replacement items
    return undefined;
  }

  return overpickingThreshold.value?.maxMultiplier;
});

const changeWeight = (weightList: OrderWeight[]) => {
  if (!order.value) {
    return;
  }
  order.value.amount = roundValue(
    weightList.reduce((acc, item) => item.weight + acc, 0),
  );
  order.value.quantity = weightList.filter(
    (weightItem) => weightItem.weight,
  ).length;
  order.value.weights = weightList;
};

const onEditWeightBlock = () => {
  if (!order.value) {
    return;
  }

  scannedBarcode.value = order.value.product.barcodes[0];
  if (
    !isReplacement.value &&
    roundValue(order.value.amount) < roundValue(order.value.amountOriginal) &&
    editable.value
  ) {
    dialogView.value = StatusTypes.Submit;
    toggleDialog();

    return;
  }

  order.value.status = OrderItemStatus.picked;
  emit('onEditWeightBlock', order.value, weightBefore.value);
};

const confirmText = computed(() =>
  dialogView.value === StatusTypes.Submit
    ? $t('pages.weight-list.close-dialog.confirm.text')
    : $t('pages.weight-list.close-dialog.discard.text'),
);

const titleText = computed(() => {
  let unitToDisplayText = '';
  if (order.value) {
    unitToDisplayText =
      unitToDisplay(
        order.value?.unit,
        roundValue(order.value?.amountOriginal),
      ) ?? '';
  }

  return dialogView.value === StatusTypes.Submit
    ? `You only picked ${
        order.value && order.value.amount ? roundValue(order.value.amount) : 0
      } out of
        ${
          order.value && order.value.amountOriginal
            ? roundValue(order.value.amountOriginal)
            : 0
        } ${unitToDisplayText}. Do you really want to complete the pick?`
    : $t('pages.weight-list.close-dialog.title.text');
});

const barcodeService = vueBarcodePlugin.get();

watch(visible, async (isVisible) => {
  if (isVisible && scannedBarcode.value) {
    await handleEmbeddedBarcode(scannedBarcode.value);
  }
});

barcodeService.onBarcode({
  async next(barcode: string) {
    if (!order.value) {
      return;
    }
    scannedBarcode.value = barcode;
    const weight = ref(parseInt(barcode.substr(1, 4)));
    switch (order.value?.unit.toLowerCase()) {
      case ItemUnit.Kilogram:
        weight.value = parseFloat((weight.value / 1000).toFixed(2));
        break;
      case ItemUnit.Pound:
        weight.value = parseFloat((weight.value / 100).toFixed(2));
        break;
      default:
        weight.value = Number(weight.value);
        break;
    }

    const weightList = order.value.weights
      ? order.value.weights.filter((item) => item.weight > 0)
      : [];
    weightList.push({
      weight: weight.value,
      relatedBarcode: barcode,
    });
    weightList.push({
      weight: 0,
      relatedBarcode: barcode,
    });
    changeWeight(weightList);

    //If the total weight exceeded the threshold
    if (isOverpickingThresholdReached.value) {
      await deviceFeedbackServicePlugin
        .get()
        .trigger(playlistActionRequiredBarcodeScan);

      return;
    }

    await deviceFeedbackServicePlugin.get().trigger(playlistSuccessBarcodeScan);

    return;
  },
  pattern: 'ScaleRegexp',
});

const handleEmbeddedBarcode = async (barcode: string) => {
  loggerServicePlugin
    .get()
    .debug(`Barcode scanned with open WeightPopup: ${barcode}`);
  if (!order.value || !orderItems.value) {
    return;
  }
  scannedBarcode.value = barcode;
  const resultForItem = await barcodeServicePlugin
    .get()
    .findItemByBarcode(orderItems.value, barcode);

  //If the scanned product is not part of the order
  if (!resultForItem.item) {
    errorPlugin
      .get()
      .handle(new BarcodeNotFoundInOrderError({ barcodeValue: barcode }));

    await deviceFeedbackServicePlugin
      .get()
      .trigger(playlistActionRequiredBarcodeScan);

    return;
  }

  //If the scanned product is part of the order, but it's not the started RWT order item
  if (
    resultForItem.item &&
    resultForItem.item.product.sku !== order.value.product.sku
  ) {
    notificationPlugin.get().show({
      text: $t('errors.barcode.product-picking-complete-this.message'),
      type: NotificationType.Info,
    });

    await deviceFeedbackServicePlugin
      .get()
      .trigger(playlistActionRequiredBarcodeScan);

    return;
  }

  const weightList = order.value.weights
    ? order.value.weights.filter((item) => item.weight > 0)
    : [];

  const totalWeight = weightList.reduce(
    (acc: number, item: OrderWeight) => item.weight + acc,
    0,
  );

  totalWeightReached.value =
    totalWeight >= order.value.amountOriginal && !order.value.originalId;

  //If the total weight is more than the required original amount
  if (totalWeight >= order.value.amountOriginal && !order.value.originalId) {
    //If the total weight exceeded the threshold
    if (isOverpickingThresholdReached.value) {
      await deviceFeedbackServicePlugin
        .get()
        .trigger(playlistActionRequiredBarcodeScan);

      return;
    }

    changeWeight(weightList);

    //If the total weight didn't exceed the threshold
    await deviceFeedbackServicePlugin
      .get()
      .trigger(playlistActionRequiredBarcodeScan);

    return;
  }

  weightList.push({
    weight: resultForItem.weight || 0,
    relatedBarcode: barcode,
  });
  changeWeight(weightList);

  //If the total weight exceeded the threshold
  if (isOverpickingThresholdReached.value) {
    await deviceFeedbackServicePlugin
      .get()
      .trigger(playlistActionRequiredBarcodeScan);

    return;
  }

  await deviceFeedbackServicePlugin.get().trigger(playlistSuccessBarcodeScan);
};

barcodeService.onBarcode({
  next: handleEmbeddedBarcode,
  pattern: 'EmbeddedBarcodeRegexp',
});

const barcodeRelatedProduct = computed(
  () => order.value?.product.rwpType === 1 && props.showScannedBarcodePreview,
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(order) && _unref(order).product)
      ? (_openBlock(), _createBlock(_unref(Popup), {
          key: 0,
          bottom: true,
          visible: Boolean(_unref(visible) && _unref(order) && _unref(order).product),
          "fixed-header": "",
          "full-page-size": "",
          onClose: closeWeightBlock
        }, {
          header: _withCtx(() => [
            _createElementVNode("h3", _hoisted_1, _toDisplayString(_unref(order).product.productName), 1)
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(Image), {
                e2e: `picking-img-${_unref(order).product.sku}`,
                image: _unref(order).product.image,
                title: _unref(order).product.productName
              }, null, 8, ["e2e", "image", "title"])
            ]),
            _createVNode(_unref(WeightList), {
              barcode: scannedBarcode.value,
              barcodeRelatedProduct: barcodeRelatedProduct.value,
              barcodeWeights: _unref(selectedItem) ? _unref(selectedItem).weights : _unref(order).weights,
              editable: _unref(editable),
              isOverpickingThresholdReached: isOverpickingThresholdReached.value,
              showRemainingWeight: _ctx.showRemainingWeight,
              "total-order-weight": _unref(order).amountOriginal,
              totalWeightReached: totalWeightReached.value,
              unit: _unref(order).unit,
              onOnchangeWeight: changeWeight,
              isReplacement: isReplacement.value,
              isReplacementThresholdReached: isReplacementThresholdReached.value
            }, null, 8, ["barcode", "barcodeRelatedProduct", "barcodeWeights", "editable", "isOverpickingThresholdReached", "showRemainingWeight", "total-order-weight", "totalWeightReached", "unit", "isReplacement", "isReplacementThresholdReached"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_unref(WeightBlock), {
                amount: _unref(order).amount,
                "amount-original": _unref(order).amountOriginal,
                "count-weight": _unref(isOrderItemRandomWeight)(_unref(order)),
                "hide-quantity-original": !_ctx.showOriginal,
                isOverpickingThresholdReached: isOverpickingThresholdReached.value,
                quantity: _unref(order).quantity,
                "quantity-original": _unref(order).quantityOriginal,
                "show-original": _ctx.showOriginal || _ctx.showRemainingWeight,
                sku: _unref(order).product?.sku,
                unit: _unref(order).unit,
                isReplacementThresholdReached: isReplacementThresholdReached.value
              }, null, 8, ["amount", "amount-original", "count-weight", "hide-quantity-original", "isOverpickingThresholdReached", "quantity", "quantity-original", "show-original", "sku", "unit", "isReplacementThresholdReached"]),
              _createElementVNode("div", null, [
                _createVNode(_unref(Button), {
                  "data-e2e": `weight-submit-button-${_unref(order).product.sku}`,
                  btnOutline: "",
                  icon: "",
                  onClick: onEditWeightBlock,
                  disabled: 
            isOverpickingThresholdReached.value || isReplacementThresholdReached.value
          
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(CheckIcon), {
                      fill: "currentColor",
                      width: "28"
                    })
                  ]),
                  _: 1
                }, 8, ["data-e2e", "disabled"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["visible"]))
      : _createCommentVNode("", true),
    _createVNode(_unref(Dialog), {
      "confirm-text": confirmText.value,
      "title-text": titleText.value,
      visible: isAlertDisplay.value,
      onCanceled: cancelDialog,
      onConfirmed: confirmDialog
    }, null, 8, ["confirm-text", "title-text", "visible"])
  ], 64))
}
}

})