import type { Ref } from 'vue';
import { watch } from 'vue';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import type { BasicTemperatureClass } from '@/features/products/types';
import { basicTemperatureClasses } from '@/features/products/types';
import { deepClone } from '@/utils/helpers/deepClone';
import type { UseOrdersFilters, Tab as TabType } from '../types';
import { OrderLocalStatus, tabs, tabsList } from '../types';

const filteringStatus: Ref<UseOrdersFilters> = ref({});
const temperatureClasses: Ref<BasicTemperatureClass[]> = ref(
  deepClone(basicTemperatureClasses),
);

export const useFiltersAndTabs = () => {
  const route = useRoute();
  const currentTab = computed(
    () =>
      tabsList.find((tab) => route.hash.includes(tab.linkTo)) ?? tabs.Picking,
  );

  /**
   * Refs & Properties
   */
  const defaultHandoverFilters = {
    temperatureClasses: undefined,
    sortBy: undefined,
    sort: undefined,
    status: tabs.Handover.status,
    localStatus: [tabs.Handover.localStatus, OrderLocalStatus.Paused],
  };

  filteringStatus.value = {
    status: currentTab.value.status,
    localStatus: [currentTab.value.localStatus, OrderLocalStatus.Paused],
    ...filteringStatus.value,
  };

  /**
   * Computed Properties
   */
  const isHandoverTab = computed(() => currentTab.value === tabs.Handover);
  const isFiltering = computed(
    () =>
      !!(
        filteringStatus.value.temperatureClasses?.length ||
        (filteringStatus.value.sortBy &&
          filteringStatus.value.sortBy !== 'startTime') ||
        (filteringStatus.value.sort && filteringStatus.value.sort !== 'asc')
      ),
  );

  /**
   * Methods
   */
  const updateFilterTempClasses = (activeClasses: string[]) => {
    temperatureClasses.value = temperatureClasses.value.map((tempClass) => {
      const isActive = activeClasses.includes(tempClass.name);
      return { ...tempClass, active: isActive };
    });
  };

  const clearFilter = () => {
    updateFilterTempClasses([]);
    filteringStatus.value.temperatureClasses = undefined;
    filteringStatus.value.sortBy = undefined;
    filteringStatus.value.sort = undefined;
  };

  const changeTab = (tab: TabType) => {
    filteringStatus.value.status = tab.status;
    filteringStatus.value.localStatus = [
      tab.localStatus,
      OrderLocalStatus.Paused,
    ];
  };

  watch(currentTab, (tab: TabType) => changeTab(tab), { immediate: true });

  return {
    isFiltering,
    filteringStatus,
    defaultHandoverFilters,
    temperatureClasses,
    currentTab,
    isHandoverTab,
    updateFilterTempClasses,
    clearFilter,
    tabsList,
  };
};
