import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["data-e2e"]
const _hoisted_2 = { class: "card__title mb-2" }
const _hoisted_3 = ["data-e2e"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["data-e2e"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "card__subheader-wrapper"
}
const _hoisted_8 = { class: "card__subheader-row" }
const _hoisted_9 = ["data-e2e"]
const _hoisted_10 = { class: "card__subheader" }
const _hoisted_11 = ["data-e2e"]
const _hoisted_12 = { class: "card__subheader-total" }
const _hoisted_13 = ["data-e2e"]
const _hoisted_14 = {
  key: 2,
  class: "card__subheader"
}
const _hoisted_15 = { class: "card__total" }
const _hoisted_16 = ["data-e2e"]
const _hoisted_17 = {
  key: 0,
  class: "d-flex-center icon-padding"
}
const _hoisted_18 = { key: 3 }

import type { PropType } from 'vue';
import { computed, ref, toRefs } from 'vue';
import { $t } from '@/i18n';
import { BaseButton } from '@/features/ui';
import { BagIcon, CartIcon, InfoIcon } from '@/features/ui/icons';
import { ProductBox } from '@/features/products';
import { ChipTime } from '@/features/ui/components';
import { formatHours, formatTimeDifference } from '@/composables/useDateFormat';
import { useOnlineStatus } from '@/features/orders';
import type { Order } from '@/features/orders';
import { isOrderPicking } from '@/features/orders';
import { OrderLocalStatus } from '@/features/orders';

enum Events {
  InfoClick = 'infoClick',
  GoNext = 'goNext',
}


export default /*@__PURE__*/_defineComponent({
  __name: 'OrderCard',
  props: {
  card: {
    type: Object as PropType<Order>,
    default: () => {
      return {};
    },
  },
  e2e: {
    type: String,
    default: 'order',
  },
  isTimeDuration: {
    type: Boolean,
    default: false,
  },
  disabledItem: Array,
  productBox: {
    type: Boolean,
    default: true,
  },
  disabledProcess: {
    type: Boolean,
    default: false,
  },
},
  emits: Object.values(Events),
  setup(__props) {

const props = __props;



const { card } = toRefs(props);

const formattedTime = computed(() => {
  if (card.value.checkIn?.timestamp) {
    return card.value.checkIn.timestamp;
  }
  if (props.isTimeDuration) {
    return formatTimeDifference(card.value.startTime, Date.now());
  }

  return isPicking
    ? formatHours(card.value.startTime)
    : formatHours(card.value.startTime) +
        ' - ' +
        formatHours(card.value.endTime);
});

const { isOnline } = useOnlineStatus();

const processing = ref(false);

const isPicking = isOrderPicking(card.value);

const pickupCode = card.value.pickupCode;

const nextButtonType = computed(() =>
  card.value.localStatus === OrderLocalStatus.Paused ||
  card.value.localStatus === OrderLocalStatus.HandoverReady
    ? 'primary'
    : 'secondary',
);

const products = computed(() => card.value.items.map((item) => item.product));

const isAgeRestricted = computed(() => {
  if (card.value.ageVerification) {
    return card.value.ageVerification?.requiredAge > 0;
  }
  return false;
});

const buttonLabel = computed(() => {
  if (card.value.localStatus === OrderLocalStatus.Paused) {
    return $t('components.card-order.button-continue-picking.text');
  }

  if (card.value.localStatus === OrderLocalStatus.PickingInProgress) {
    return $t('components.card-order.button-start.text', {
      label: $t(
        'components.card-order.button-continue-picking-in-progress-order.text',
      ),
      isAgeRestricted: isAgeRestricted.value
        ? $t('components.card-order.button-age-restricted.text')
        : null,
    });
  }

  return $t('components.card-order.button-start.text', {
    label: card.value.actions[0]?.title,
    isAgeRestricted: isAgeRestricted.value
      ? $t('components.card-order.button-age-restricted.text')
      : null,
  });
});

const disabledSubmitButton = computed(
  () =>
    (isPicking && (processing.value || !isOnline.value)) ||
    (!isPicking && processing.value) ||
    props.disabledProcess,
);

const cardArticlesAmount = ref(card.value?.items?.length);
const cardBagsAmount = ref(
  card.value?.deliveryUnits.length ? card.value?.deliveryUnits.length : 0,
);
const cardItemsTotal = ref(card.value?.itemsCount?.total);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "card card--wide",
    "data-e2e": `order-box-${_unref(card).id}`
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([
          'card__time',
          { 'card__time--accent': _unref(card).checkIn.timestamp || __props.isTimeDuration },
        ]),
        "data-e2e": `time-label-${_unref(card).id}`
      }, [
        _renderSlot(_ctx.$slots, "time", {}, () => [
          (_unref(card).checkIn.timestamp)
            ? (_openBlock(), _createBlock(_unref(ChipTime), {
                key: 0,
                time: _unref(card).checkIn.timestamp,
                "label-view": true
              }, null, 8, ["time"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(formattedTime.value), 1))
        ]),
        _renderSlot(_ctx.$slots, "timeBadge")
      ], 10, _hoisted_3),
      _createElementVNode("div", {
        class: "card__id",
        "data-e2e": `code-label-${_unref(card).id}`
      }, [
        _createTextVNode(_toDisplayString(_unref(isPicking) ? _unref(card).orderReference : _unref(pickupCode)) + " ", 1),
        (!_unref(isPicking))
          ? (_openBlock(), _createBlock(_unref(InfoIcon), {
              key: 0,
              width: "18px",
              "data-e2e": `order-note-button-${_unref(card).id}`,
              onClick: _cache[0] || (_cache[0] = () => _ctx.$emit(Events.InfoClick, _unref(card)))
            }, null, 8, ["data-e2e"]))
          : _createCommentVNode("", true)
      ], 8, _hoisted_5)
    ]),
    (_unref(isPicking))
      ? (_openBlock(), _createElementBlock("hr", _hoisted_6))
      : _createCommentVNode("", true),
    (!_unref(isPicking))
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("span", {
              "data-e2e": `card-customer-name-title-${_unref(card).id}`
            }, _toDisplayString(_unref($t)('components.card-order.name.text')), 9, _hoisted_9)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", {
              class: "card__subheader-name",
              "data-e2e": `card-customer-lastname-${_unref(card).id}`
            }, _toDisplayString(_unref(card).customer.lastName), 9, _hoisted_11),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("span", {
                "data-e2e": `card-bags-amount-${_unref(card).id}`
              }, _toDisplayString(cardBagsAmount.value), 9, _hoisted_13),
              _createVNode(_unref(BagIcon), {
                fill: "#0d3a93",
                width: "18px"
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_unref(isPicking))
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_unref(CartIcon)),
            _createElementVNode("span", {
              "data-e2e": `total-label-${_unref(card).id}`
            }, _toDisplayString(_unref($t)('components.order-card.orders-total.picking.text', {
              articleAmount: cardArticlesAmount.value,
              totalItems: cardItemsTotal.value,
            })), 9, _hoisted_16)
          ]),
          (_unref(card).cartNote)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createVNode(_unref(InfoIcon), {
                  "data-e2e": `order-note-button-${_unref(card).id}`,
                  onClick: _cache[1] || (_cache[1] = () => _ctx.$emit(Events.InfoClick, _unref(card)))
                }, null, 8, ["data-e2e"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_unref(isPicking))
      ? (_openBlock(), _createElementBlock("hr", _hoisted_18))
      : _createCommentVNode("", true),
    (_unref(isPicking))
      ? (_openBlock(), _createBlock(_unref(ProductBox), {
          key: 4,
          id: _unref(card).id,
          temperatureClasses: _unref(card).temperatureClasses,
          products: _unref(card).items,
          productList: products.value,
          disabledItem: __props.disabledItem,
          isSpaceBetween: true,
          itemsCount: _unref(card).itemsCount
        }, null, 8, ["id", "temperatureClasses", "products", "productList", "disabledItem", "itemsCount"]))
      : _createCommentVNode("", true),
    _createVNode(_unref(BaseButton), {
      class: "card__action",
      label: buttonLabel.value,
      variant: nextButtonType.value,
      onClick: _cache[2] || (_cache[2] = () => _ctx.$emit(Events.GoNext)),
      disabled: disabledSubmitButton.value,
      "data-e2e": `start-picking-button-${_unref(card).id}`
    }, null, 8, ["label", "variant", "disabled", "data-e2e"])
  ], 8, _hoisted_1))
}
}

})