import { ProviderPluginFactory } from '@/features/core/plugin';
import type { EnvironmentConfigurationOptions } from './environment-configuration';
import { EnvironmentConfiguration } from './environment-configuration';

export const environmentConfigurationPlugin = ProviderPluginFactory.create<
  EnvironmentConfiguration,
  EnvironmentConfigurationOptions
>({
  key: Symbol('EnvironmentConfigurationPlugin'),
  defaultFactory: {
    create: (app, options) => new EnvironmentConfiguration(options),
  },
});
