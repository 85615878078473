import { computed, onBeforeUnmount, onMounted } from 'vue';
import { useNewIncomingOrders, useOrders } from '@/features/orders/composables';
import { isDueSoonOrder, isPausedOrder } from '@/features/orders/helpers';
import { configurationServicePlugin } from '@/features/configuration';
import { OrderMetadata } from '@/features/orderMetadata';

const { orders, loadOrders } = useOrders();
export const usePickingOrders = () => {
  const getPickingBuffer = async () =>
    await configurationServicePlugin
      .get()
      .getFeatureOption('picking', 'pickingBuffer', 'number');

  let pickingBuffer = 0;

  const pausedOrders = computed(() => {
    return orders.value.filter((item) => isPausedOrder(item.localStatus));
  });

  const dueSoon = computed(() =>
    orders.value.filter(
      (order) =>
        !isPausedOrder(order.localStatus) &&
        isDueSoonOrder(order.startTime, pickingBuffer),
    ),
  );

  const openOrders = computed(() => {
    const _openOrders = orders.value.filter(
      (item) => !isPausedOrder(item.localStatus),
    );

    return _openOrders.filter(
      (order) => !isDueSoonOrder(order.startTime, pickingBuffer),
    );
  });

  const { bufferedNewOrdersMetadata, setOrderMetadata, orderMetadataService } =
    useNewIncomingOrders();

  const newOrdersLength = computed(
    () =>
      orders.value.filter(({ orderMetadata }) =>
        orderMetadataService?.isOrderUnseen(
          orderMetadata ?? OrderMetadata.from({ id: '', seen: true }),
        ),
      ).length,
  );

  onBeforeUnmount(async () => {
    await setOrderMetadata(Array.from(bufferedNewOrdersMetadata.values()));
  });

  onMounted(async () => {
    pickingBuffer = await getPickingBuffer();
  });

  return {
    pickingBuffer,
    pausedOrders,
    dueSoon,
    openOrders,
    orders,
    newOrdersLength,
    loadOrders,
  };
};
