import type { ServiceWorkerService } from '@/features/push-notification/services/serviceWorkerService';
import type { PushNotificationDTO } from '@/features/push-notification/types';
import type { PipelinePlugin } from '@ads/plugin-pipeline';

/**
 * Initialize service worker
 */

export class InitializeServiceWorkerPlugin
  implements PipelinePlugin<PushNotificationDTO>
{
  constructor(private serviceWorker: ServiceWorkerService) {}

  public async execute(
    dataTransferObject: PushNotificationDTO,
  ): Promise<PushNotificationDTO> {
    await this.serviceWorker.init();
    return dataTransferObject;
  }
}
