import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { $t } from '@/i18n';
import { formatDate } from '@/composables/useDateFormat';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';
import { vueBarcodePlugin } from '@/features/barcode';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import {
  OrderLocalStatus,
  useFiltersAndTabs,
  useInProgressOrders,
  useOrders,
} from '@/features/orders';
import type {
  UseOrderSearch,
  UseOrdersFilters,
  SearchComponent,
} from '@/features/orders';
import { BarcodeScanEvent } from '@/features/orders';

export function useOrderSearch(): UseOrderSearch {
  /**
   * Refs & Properties
   */
  const router = useRouter();
  const search: Ref<string> = ref('');
  const searchFocused = ref(false);
  const isSearchActive: Ref<boolean> = ref(false);
  //const searchRef = ref<null | typeof Search>(null);
  const searchTitle = $t('pages.order-overview.search-title.text', {
    date: formatDate(new Date()),
  });

  const { searchedOrders, searchOrders } = useOrders();
  const { isHandoverTab } = useFiltersAndTabs();
  const { isPickingInProgressOrdersVisible } = useInProgressOrders();

  const searchOrdersByValues = (
    searchValue: string,
    filteringStatus: UseOrdersFilters,
  ) => {
    const searchParams = {
      search: searchValue,
      searchQueries: ['orderReference'],
      ...filteringStatus,
    };

    if (isHandoverTab.value) {
      searchParams.searchQueries = ['orderReference', 'pickupCode'];
      searchParams.temperatureClasses = undefined;
      searchParams.sortBy = undefined;
      searchParams.sort = undefined;
    }

    if (isPickingInProgressOrdersVisible.value && !isHandoverTab.value) {
      searchParams.localStatus = [
        ...(filteringStatus.localStatus || []),
        OrderLocalStatus.PickingInProgress,
      ];
    }

    void searchOrders(searchParams);
  };

  /**
   * Computed Properties
   */
  const isSearching = computed(
    () => isSearchActive.value && search.value?.length >= 2,
  );

  /**
   * Methods
   */
  const toggleSearch = () => (searchFocused.value = !searchFocused.value);
  const getBarcodeData = (barcode: string) => {
    const barcodeData = barcode.split('|');
    return {
      tabHash: `#${barcodeData[0]}`,
      orderReference: barcodeData[1],
    };
  };

  const setBarcodeReader = (searchRef: Ref<null | SearchComponent>) => {
    const barcodeService = vueBarcodePlugin.get();
    barcodeService.onBarcode({
      async next(barcode: string) {
        const { orderReference, tabHash } = getBarcodeData(barcode);
        await router.push({ path: '/', hash: tabHash });
        if (searchRef?.value) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (!searchRef.value.showSearch) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            searchRef.value.toggleSearch();
          }
          search.value = orderReference;

          eventBusServicePlugin.get().emit(new BarcodeScanEvent());
        }
      },

      validationError() {
        notificationPlugin.get().show({
          text: $t('errors.barcode.not-supported-qr-code.message'),
          type: NotificationType.Error,
        });
      },
      pattern: 'CheckinRegexp',
    });
  };

  const activatedSearch = (active: boolean) => {
    isSearchActive.value = active;
  };

  return {
    search,
    isSearchActive,
    searchTitle,
    isSearching,
    searchFocused,

    toggleSearch,
    activatedSearch,
    setBarcodeReader,

    searchedOrders,
    searchOrdersByValues,
  };
}
