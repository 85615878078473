import type { EventBus } from '@/features/core/event-bus';
import type { Storage } from '@/features/core/storage';
import type { OrderMetadataService } from '@/features/orderMetadata/types';
import { UpdateOrderUIPlugin } from '@/features/orders/plugins/update-order-ui';
import type { ProcessedOrderServiceInterface } from '@/features/processed-order/types';
import {
  CancelSyncByIdsPlugin,
  RemoveEntityByIdPlugin,
  RemoveOrderMetadataByIdsPlugin,
  RemoveProcessedOrdersByIdsPlugin,
} from '@/features/push-notification/plugins';
import type { SyncSchedulerService } from '@/features/sync-scheduler';
import { PluginPipeline } from '@ads/plugin-pipeline';
import type { DeleteOrderDTO } from '../types';

export const getDeleteOrderPipeline = (
  syncScheduler: SyncSchedulerService,
  storage: Storage,
  orderMetadataService: OrderMetadataService,
  processedOrderService: ProcessedOrderServiceInterface,
  eventBusService: EventBus,
) => {
  const deleteOrderPlugin = new PluginPipeline<DeleteOrderDTO>();

  deleteOrderPlugin.registerPlugins([
    new CancelSyncByIdsPlugin(syncScheduler),
    new RemoveEntityByIdPlugin(storage),
    new RemoveOrderMetadataByIdsPlugin(orderMetadataService),
    new RemoveProcessedOrdersByIdsPlugin(processedOrderService),
    new UpdateOrderUIPlugin(eventBusService),
  ]);

  return deleteOrderPlugin;
};
