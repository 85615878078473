import type { Storage } from '@/features/core/storage';
import { Order } from '@/features/orders/entities';
import type { PushNotificationDTO } from '@/features/push-notification/types';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import { InitializeServiceWorkerPlugin } from './initialize-service-worker';
import { RecognizeEntityTypePlugin } from './recognize-entity-type';

/**
 * Get entities by ids from the storage
 * Store them to DTO storedEntities
 */

export class LoadStoredEntitiesPlugin
  implements PipelinePlugin<PushNotificationDTO>
{
  private static instance: LoadStoredEntitiesPlugin;

  public readonly requiredPlugins = [
    InitializeServiceWorkerPlugin,
    RecognizeEntityTypePlugin,
  ];

  constructor(private storage: Storage) {}

  public static getInstance(storage: Storage): LoadStoredEntitiesPlugin {
    if (!LoadStoredEntitiesPlugin.instance) {
      LoadStoredEntitiesPlugin.instance = new LoadStoredEntitiesPlugin(storage);
    }
    return LoadStoredEntitiesPlugin.instance;
  }

  public async execute(
    dataTransferObject: PushNotificationDTO,
  ): Promise<PushNotificationDTO> {
    dataTransferObject.storedEntities = await this.storage.getByIds(Order, {
      ids: dataTransferObject.ids,
    });
    return dataTransferObject;
  }
}
