import {
  OrderMetadata,
  orderMetadataServicePlugin,
  showAsUnseenMinutes,
} from '@/features/orderMetadata';

export const useNewIncomingOrders = () => {
  /**
   * Refs & Props
   */
  const orderMetadataService = orderMetadataServicePlugin.get();

  const bufferedNewOrdersMetadata = new Map<string, OrderMetadata>();

  /**
   * Methods
   */
  const isNewOrderBadgeVisible = (metadata: OrderMetadata) => {
    return orderMetadataService?.isOrderUnseen(
      metadata ?? OrderMetadata.from({ id: '', seen: true }),
    );
  };

  const setOrderMetadata = async (ordersMetadata: OrderMetadata[]) => {
    const currentDate = new Date();
    const untilDate = new Date(
      currentDate.setMinutes(currentDate.getMinutes() + showAsUnseenMinutes),
    );

    const newOrdersMetadata = ordersMetadata
      .map((orderMetadata) => {
        if (!orderMetadata?.id) {
          return null;
        }

        const newMetadata = OrderMetadata.from({
          id: orderMetadata.id,
          seen: true,
        });

        if (!orderMetadata?.seen) {
          newMetadata.showAsUnseenUntil = untilDate;
        }

        return OrderMetadata.from(newMetadata);
      })
      .filter((metadata): metadata is OrderMetadata => metadata !== null);

    await orderMetadataService.bulkSave(newOrdersMetadata);
  };

  return {
    setOrderMetadata,
    isNewOrderBadgeVisible,
    bufferedNewOrdersMetadata,
    orderMetadataService,
  };
};
