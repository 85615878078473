
import type { PropType } from 'vue';
import { computed, defineComponent, ref, watch } from 'vue';
import type { OrderItem } from '@/features/orders/types';
import { CheckIcon, MinusIcon, PlusIcon } from '@/features/ui/icons';
import { Button, Input } from '@/features/ui/components';

enum Events {
  Remove = 'remove',
  Restore = 'restore',
  Reject = 'reject',
  FocusOut = 'focusOut',
  FocusIn = 'focusIn',
  IncreaseTo = 'increaseTo',
  DecreaseTo = 'decreaseTo',
  Update = 'update',
  Apply = 'apply',
  Input = 'input',
}

export default defineComponent({
  name: 'CardRowFloatingFooterDefault',
  props: {
    orderItem: {
      type: Object as PropType<OrderItem>,
      default: () => ({}),
    },
    isControlsDisabled: {
      type: Boolean,
      default: false,
    },
    hideApplyButton: {
      type: Boolean,
      default: false,
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
    isRestorable: {
      type: Boolean,
      default: true,
    },
    quantity: {
      type: Number,
      default: 0,
    },
    quantityOriginal: {
      type: Number,
      default: 0,
    },
    quantityOriginalFilteredByStatus: {
      type: Number,
      default: 0,
    },
    noQuantityOriginal: {
      type: Boolean,
      default: false,
    },
    e2eTag: {
      type: String,
      default: 'pickup',
    },
    e2eValue: {
      type: String,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disableSubmit: {
      type: Boolean,
      default: false,
    },
    allowSubmitMaxQuantity: {
      type: Boolean,
      default: false,
    },
  },
  emits: Object.values(Events),
  components: {
    MinusIcon,
    PlusIcon,
    CheckIcon,
    Button,
    Input,
  },
  setup(props, { emit }) {
    const quantity = ref(props.quantity);

    watch(
      () => props.quantity,
      (newValue) => {
        quantity.value = newValue;
      },
    );

    const isValid = computed(
      () =>
        quantity.value >= 0 &&
        quantity.value <= props.quantityOriginalFilteredByStatus,
    );

    const canDecrease = computed(() => quantity.value > 0);

    const canIncrease = computed(
      () =>
        !props.error && quantity.value < props.quantityOriginalFilteredByStatus,
    );

    const updateValue = (value: number | string) => {
      quantity.value = typeof value === 'string' ? parseInt(value) : value;
      emit(Events.Update, quantity);
    };

    const canSubmit = computed(() => {
      if (props.error || props.disableSubmit) {
        return false;
      }
      return (
        quantity.value >= 0 &&
        ((!props.allowSubmitMaxQuantity &&
          quantity.value < props.quantityOriginalFilteredByStatus) ||
          (props.allowSubmitMaxQuantity &&
            quantity.value <= props.quantityOriginalFilteredByStatus))
      );
    });

    const decreaseQuantity = () => {
      if (canDecrease.value) {
        quantity.value -= 1;
        emit(Events.DecreaseTo, quantity.value);
      }
    };

    const increaseQuantity = () => {
      if (canIncrease.value) {
        quantity.value += 1;
        emit(Events.IncreaseTo, quantity.value);
      }
    };

    const handleApplyButton = () => {
      if (canSubmit.value) {
        emit(Events.Apply, props.orderItem, quantity.value);
      }
    };

    const onKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleApplyButton();
        const el = event.target as HTMLElement;
        el.blur();
      }
    };

    const sku = computed<string>(() => {
      return props.orderItem.product
        ? props.orderItem.product.sku
        : props.orderItem.id;
    });

    return {
      canDecrease,
      canIncrease,
      handleApplyButton,
      quantity,
      decreaseQuantity,
      increaseQuantity,
      canSubmit,
      updateValue,
      onKeyPress,
      sku,
      isValid,
      Events,
    };
  },
});
