import { apiConfigPlugin, empowerIdApiConfigPlugin } from './api';
import { authConfigPlugin } from './auth';
import { entityRepositoryConfigPlugin } from './entity-repository';
import { MultiPluginFactory } from './plugin';
import type { InferPluginOptions } from './plugin/types';
import { routerConfigPlugin } from './router';
import { storageConfigPlugin, storageSetupInitPlugin } from './storage';
import { resolverServiceConfigPlugin } from './resolver';
import { notificationConfigPlugin } from './notifications';
import { errorConfigPlugin, registerLoggerServicePlugin } from './errors';
import { eventBusPlugin } from './event-bus';
import { environmentConfigurationPlugin } from './environment-configuration';

export type CorePluginOptions = InferPluginOptions<typeof corePlugin>;

export const corePlugin = MultiPluginFactory.with({
  eventBusPlugin,
  notificationConfigPlugin,
  resolverServiceConfigPlugin,
  storageConfigPlugin,
  errorConfigPlugin,
  empowerIdApiConfigPlugin,
  authConfigPlugin,
  apiConfigPlugin,
  entityRepositoryConfigPlugin,
  routerConfigPlugin,
  registerLoggerServicePlugin,
  environmentConfigurationPlugin,
});

export const coreInitPlugin = MultiPluginFactory.with({
  storageSetupInitPlugin,
});
