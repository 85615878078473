import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { AppServiceResolver } from '@/features/core/resolver/';
import Notification from '@/features/core/notifications/components/Notification.vue';
import RouteLeaveGuard from '@/features/route-leave-guard/components/RouteLeaveGuard.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(AppServiceResolver), null, {
      ready: _withCtx(() => [
        (_openBlock(), _createBlock(_component_RouterView, {
          key: _ctx.$route.path
        }))
      ]),
      _: 1
    }),
    _createVNode(Notification),
    _createVNode(RouteLeaveGuard)
  ], 64))
}
}

})