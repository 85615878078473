import type { Ref } from 'vue';
import { PluginPipeline } from '@ads/plugin-pipeline';
import { errorPlugin } from '@/features/core/errors';
import router from '@/features/core/router';
import type { Order, OrderPluginDto } from '@/features/orders';
import {
  BooleanSwitcherPlugin,
  HandleRestrictedOrderPlugin,
  NavigateToTargetRoutePlugin,
  SaveOrderToStoragePlugin,
  SetCustomerNoteAsTargetRoutePlugin,
  SetPickingEventsAsAlreadyPatchedPlugin,
  SetTargetRoutePlugin,
  StartPerformanceTrackingPlugin,
  TrackOrderEventByLocalStatusPlugin,
  ordersServicePlugin,
} from '@/features/orders';
import { PipelineExecutionError } from '@ads/plugin-pipeline/build/pipeline-execution-error';
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';
import { AcknowledgeCheckInNotificationPlugin } from '@/features/push-notification/plugins/acknowledge-check-in-notification';
import { eventBusServicePlugin } from '@/features/core/event-bus';

export async function startHandoverProcess(
  order: Order,
  switcher: Ref<boolean>,
): Promise<void> {
  const performanceTrackerComposable = usePerformanceTracker();
  try {
    const startOrderPlugin = new PluginPipeline<OrderPluginDto>();
    startOrderPlugin.registerPlugins([
      new HandleRestrictedOrderPlugin(),
      new BooleanSwitcherPlugin(switcher, true),
      new SetPickingEventsAsAlreadyPatchedPlugin(),
      new SetTargetRoutePlugin({
        name: 'bags-collection',
        params: {
          id: order.id,
        },
      }),
      new TrackOrderEventByLocalStatusPlugin(),
    ]);

    startOrderPlugin.registerPlugins(
      [
        new AcknowledgeCheckInNotificationPlugin(
          ordersServicePlugin.get(),
          eventBusServicePlugin.get(),
        ),
      ],
      (dto) =>
        dto.order.checkIn?.isCheckedIn === 1 &&
        !dto.order.checkIn?.isAcknowledged,
    );

    startOrderPlugin.registerPlugins([
      new StartPerformanceTrackingPlugin(
        performanceTrackerComposable,
        `start-handover-process-${order.id}`,
        PerformanceThresholdEnum.ROUTE_CHANGE,
      ),
      new SaveOrderToStoragePlugin(),
      new SetCustomerNoteAsTargetRoutePlugin(),
      new NavigateToTargetRoutePlugin(),
      new BooleanSwitcherPlugin(switcher, false),
    ]);

    await startOrderPlugin.execute({
      order,
    });
  } catch (error) {
    switcher.value = false;
    if (error instanceof PipelineExecutionError) {
      errorPlugin.get().handle(error.originalError);
    } else {
      errorPlugin.get().handle(error);
    }

    performanceTrackerComposable.cancelTracking(
      `start-handover-process-${order.id}`,
    );

    await router.get().push('/');
  } finally {
    performanceTrackerComposable.stopTracking(
      `start-handover-process-${order.id}`,
    );
  }
}
