import type { SyncSchedulerService } from '@/features/sync-scheduler';
import { SyncSchedulerExecutorFilterDto } from '@/features/sync-scheduler/services/sync-scheduler-executor';
import type { PipelinePlugin } from '@ads/plugin-pipeline';

interface CancelSyncByIdsPluginDTO {
  ids: string[];
}

/**
 * Remove syncScheduler syncs with IDs from DTO
 */
export class CancelSyncByIdsPlugin<T extends CancelSyncByIdsPluginDTO>
  implements PipelinePlugin<T>
{
  constructor(private syncScheduler: SyncSchedulerService) {}

  public async execute(dto: T): Promise<T> {
    await Promise.allSettled(
      dto.ids.map(async (id) => {
        const syncIdsList = await this.syncScheduler.getAllSyncByEntity(
          new SyncSchedulerExecutorFilterDto('order', id),
        );
        return Promise.allSettled(
          syncIdsList.map((sync) => {
            return sync.cancel();
          }),
        );
      }),
    );
    return dto;
  }
}
