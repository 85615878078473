import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["data-e2e"]

import { ItemsGroup } from '@/features/ui/components';
import {
  useOrdersOverview,
  useNewIncomingOrders,
  OrderCard,
} from '@/features/orders';
import { toRefs } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DueSoonOrderList',
  props: {
  disabledProcess: {
    type: Boolean,
    default: false,
  },
  dueSoonOrders: {
    type: Array,
    default: () => [],
  },
},
  setup(__props) {

const props = __props;

const { disabledProcess, dueSoonOrders } = toRefs(props);

const { startOrder, setCardsRefs, toggleNote } = useOrdersOverview();

const { isNewOrderBadgeVisible } = useNewIncomingOrders();

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createBlock(_unref(ItemsGroup), {
    title: `Due Soon (${_unref(dueSoonOrders).length})`,
    "data-e2e": "due-soon-list-title"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dueSoonOrders), (order) => {
        return (_openBlock(), _createBlock(_unref(OrderCard), {
          key: order.id,
          ref_for: true,
          ref: (el) => _unref(setCardsRefs)(el, order.id),
          card: order,
          disabledProcess: _unref(disabledProcess),
          isTimeDuration: "",
          onGoNext: ($event: any) => (_unref(startOrder)(order)),
          onInfoClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(toggleNote)($event)))
        }, _createSlots({ _: 2 }, [
          (_unref(isNewOrderBadgeVisible)(order.orderMetadata))
            ? {
                name: "timeBadge",
                fn: _withCtx(() => [
                  _createElementVNode("span", {
                    "data-e2e": `new-order-badge-${order.id}`,
                    class: "new-order-badge"
                  }, null, 8, _hoisted_1)
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["card", "disabledProcess", "onGoNext"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["title"])), [
    [_vShow, _unref(dueSoonOrders).length]
  ])
}
}

})