export const defaultRedactedKeys = [
  'firstName',
  'lastName',
  'dateOfBirth',
  'Authorization',
  'address',
  'address1',
  'address2',
  'city',
  'phoneNumber',
  'zipCode',
  'customerNote',
  'licensePlate',
  'floor',
];

export const supportedDynatraceEnvironments = ['dev', 'qa', 'qa_au', 'prod'];
