import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-e2e"]

import { $t } from '@/i18n';
import { ItemsGroup } from '@/features/ui/components';
import { toRefs } from 'vue';
import {
  isDueSoonOrder,
  useNewIncomingOrders,
  useOrdersOverview,
  OrderCard,
} from '@/features/orders';


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchedOrderList',
  props: {
  disabledProcess: {
    type: Boolean,
    default: false,
  },
  searchedOrders: {
    type: Array,
    default: () => [],
  },
  isSearching: {
    type: Boolean,
    default: false,
  },
  pickingBuffer: {
    type: Number,
    default: 0,
  },
},
  setup(__props) {

const props = __props;

const { disabledProcess, searchedOrders } = toRefs(props);

const { startOrder, setCardsRefs, toggleNote } = useOrdersOverview();

const { isNewOrderBadgeVisible } = useNewIncomingOrders();

return (_ctx: any,_cache: any) => {
  return (__props.isSearching && _unref(searchedOrders).length)
    ? (_openBlock(), _createBlock(_unref(ItemsGroup), {
        key: 0,
        title: `${_unref($t)('components.search-result-feedback.results.text')} (${
      _unref(searchedOrders).length
    })`,
        "data-e2e": "search-orders-list-title"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(searchedOrders), (order) => {
            return (_openBlock(), _createBlock(_unref(OrderCard), {
              key: order.id,
              ref_for: true,
              ref: (el) => _unref(setCardsRefs)(el, order.id),
              card: order,
              disabledProcess: _unref(disabledProcess),
              isTimeDuration: _unref(isDueSoonOrder)(order.startTime, __props.pickingBuffer),
              onGoNext: ($event: any) => (_unref(startOrder)(order)),
              onInfoClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(toggleNote)($event)))
            }, _createSlots({ _: 2 }, [
              (_unref(isNewOrderBadgeVisible)(order.orderMetadata))
                ? {
                    name: "timeBadge",
                    fn: _withCtx(() => [
                      _createElementVNode("span", {
                        "data-e2e": `new-order-badge-${order.id}`,
                        class: "new-order-badge"
                      }, null, 8, _hoisted_1)
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["card", "disabledProcess", "isTimeDuration", "onGoNext"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}
}

})