
import type { PropType } from 'vue';
import { computed, defineComponent, nextTick, onUnmounted, ref } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { Popup } from '@/features/ui/components';
import {
  ArrowLeftIcon,
  BarcodeScannerIcon,
  BaseIcon,
  ClearIcon,
  ProfileIcon,
  SearchIcon,
} from '@/features/ui/icons';
import BackButton from '@/features/ui/components/BackButton.vue';
// NOTE:  Failed to resolve component, when importing via barrel
import Profile from '@/features/login/views/Profile.vue';
import { BarcodeScanEvent } from '@/features/orders/checkin';
import { eventBusServicePlugin } from '@/features/core/event-bus';
import { useTransmissions } from '@/features/transmissions';

export default defineComponent({
  name: 'Search',
  props: {
    searchTitle: {
      type: String,
      default: '',
    },
    placeholderTitle: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    textClearable: {
      type: Boolean,
      default: true,
    },
    showBarcodeBlock: {
      type: Boolean,
      default: false,
    },
    searchDisabled: {
      type: Boolean,
      default: false,
    },
    inputMode: {
      type: String,
      default: 'text',
    },
    isBackExist: {
      type: Boolean,
      default: false,
    },
    isProfileExist: {
      type: Boolean,
      default: true,
    },
    backTo: {
      type: [String, Object] as PropType<RouteLocationRaw>,
      default: '/',
    },
  },
  components: {
    Profile,
    ArrowLeftIcon,
    ClearIcon,
    SearchIcon,
    ProfileIcon,
    BaseIcon,
    Popup,
    BarcodeScannerIcon,
    BackButton,
  },
  setup(props, { emit }) {
    const { hasFailedTransmissions } = useTransmissions();

    const showSearch = ref(false);
    const visibleProfile = ref(false);
    const hasValue = computed(() =>
      Boolean(
        typeof props.modelValue === 'string'
          ? props.modelValue.length
          : props.modelValue,
      ),
    );
    const searchInput = ref<HTMLInputElement | null>(null);

    const toggleSearch = () => {
      if (props.searchDisabled) {
        return;
      }
      showSearch.value = !showSearch.value;
      if (showSearch.value) {
        void nextTick(() => {
          searchInput.value?.focus();
        });
      } else {
        clearInput();
      }

      emit('search-activated', Boolean(showSearch.value));
    };
    const toggleProfile = () => {
      visibleProfile.value = !visibleProfile.value;
    };

    const closeProfile = () => {
      visibleProfile.value = false;
    };

    const updateData = () => {
      closeProfile();
      emit('update');
    };

    const clearInput = () => {
      emit('update:modelValue', null);
      void nextTick(() => {
        if (searchInput.value) {
          searchInput.value.focus();
        }
      });
    };

    const updateInput = (e: Event) => {
      const target = e.target as HTMLInputElement;

      emit('update:modelValue', target.value);
    };

    const searchIconColor = computed(() =>
      props.searchDisabled ? '#d6d8da' : '#0d3a93',
    );

    const updateFocus = () => {
      emit('focus');
    };

    const subscription = eventBusServicePlugin
      .get()
      .on(BarcodeScanEvent, () => {
        void nextTick(() => {
          if (searchInput.value) {
            searchInput.value.blur();
          }
        });
      });

    onUnmounted(() => {
      if (subscription) {
        subscription.cancel();
      }
    });

    return {
      props,
      showSearch,
      toggleProfile,
      visibleProfile,
      toggleSearch,
      hasValue,
      updateData,
      searchInput,
      clearInput,
      updateInput,
      searchIconColor,
      updateFocus,
      hasFailedTransmissions,
    };
  },
});
