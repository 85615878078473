import type { PipelinePlugin } from '@ads/plugin-pipeline';
import { loggerServicePlugin } from '@/features/core/logger';
import { useOnlineStatus } from '@/features/orders';
import type { OrderPluginDto } from '@/features/orders/types';

export class LogOrderEventsPlugin implements PipelinePlugin<OrderPluginDto> {
  constructor(private message: string) {}
  public execute(dataTransferObject: OrderPluginDto): OrderPluginDto {
    const { isOnline } = useOnlineStatus();
    loggerServicePlugin
      .get()
      .info(`${this.message} ${isOnline.value ? '' : ' (offline)'}`, {
        orderId: dataTransferObject.order.id,
        orderReference: dataTransferObject.order.orderReference,
      });
    return dataTransferObject;
  }
}
