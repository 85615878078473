<template>
  <Button
    v-show="!isControlsDisabled"
    :data-e2e="`${e2eTag}-decrease-button-${e2eValue ?? sku}`"
    :disabled="!canDecrease"
    btnOutline
    class="button"
    icon
    @click="decreaseQuantity"
  >
    <MinusIcon fill="currentColor" stroke="currentColor" />
  </Button>

  <div class="footer__body">
    <div :class="{ 'counter--labels': isControlsDisabled }" class="counter">
      <Input
        v-if="!isControlsDisabled"
        :data-e2e="`${e2eTag}-quantity-label-${e2eValue ?? sku}`"
        :e2eDescription="`${e2eTag}-quantity-label-${e2eValue ?? sku}`"
        :modelValue="quantity"
        :textClearable="false"
        :valid="isValid"
        sm
        type="number"
        @focusIn="$emit(Events.FocusIn, $event)"
        @focusOut="$emit(Events.FocusOut, $event)"
        @input="$emit(Events.Input, $event)"
        @keypress="onKeyPress"
        @update:modelValue="updateValue"
      />
      <span
        v-else
        :data-e2e="`${e2eTag}-quantity-label-${e2eValue ?? sku}`"
        class="counter__item"
      >
        {{ quantity }}
      </span>
      <template v-if="!noQuantityOriginal">
        {{ $t('components.card-row-floating-footer-default.of.text') }}
        <span
          :data-e2e="`${e2eTag}-quantityOriginal-label-${e2eValue ?? sku}`"
          class="counter__item"
        >
          {{ quantityOriginal }}
        </span>
      </template>
      <template v-else
        >{{ $t('components.card-row-floating-footer-default.items.text') }}
      </template>
    </div>
    <Button
      v-show="!isControlsDisabled"
      :data-e2e="`${e2eTag}-increase-button-${e2eValue ?? sku}`"
      :disabled="!canIncrease"
      btnOutline
      class="button"
      icon
      @click="increaseQuantity"
    >
      <PlusIcon fill="currentColor" stroke="currentColor" />
    </Button>
  </div>
  <Button
    v-show="!isControlsDisabled && !hideApplyButton"
    :data-e2e="`${e2eTag}-submit-button-${e2eValue ?? sku}`"
    :disabled="!canSubmit"
    btnOutline
    class="button footer__end"
    icon
    @click="handleApplyButton"
  >
    <CheckIcon fill="currentColor" />
  </Button>
  <Button
    v-show="isDeleted"
    :data-e2e="`${e2eTag}-restore-button-${e2eValue ?? sku}`"
    :disabled="!isRestorable"
    btnOutline
    class="restore-button"
    @click="$emit(Events.Restore, sku)"
  >
    {{ $t('components.card-row-floating-footer-default.restore-item.text') }}
  </Button>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent, ref, watch } from 'vue';
import type { OrderItem } from '@/features/orders/types';
import { CheckIcon, MinusIcon, PlusIcon } from '@/features/ui/icons';
import { Button, Input } from '@/features/ui/components';

enum Events {
  Remove = 'remove',
  Restore = 'restore',
  Reject = 'reject',
  FocusOut = 'focusOut',
  FocusIn = 'focusIn',
  IncreaseTo = 'increaseTo',
  DecreaseTo = 'decreaseTo',
  Update = 'update',
  Apply = 'apply',
  Input = 'input',
}

export default defineComponent({
  name: 'CardRowFloatingFooterDefault',
  props: {
    orderItem: {
      type: Object as PropType<OrderItem>,
      default: () => ({}),
    },
    isControlsDisabled: {
      type: Boolean,
      default: false,
    },
    hideApplyButton: {
      type: Boolean,
      default: false,
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
    isRestorable: {
      type: Boolean,
      default: true,
    },
    quantity: {
      type: Number,
      default: 0,
    },
    quantityOriginal: {
      type: Number,
      default: 0,
    },
    quantityOriginalFilteredByStatus: {
      type: Number,
      default: 0,
    },
    noQuantityOriginal: {
      type: Boolean,
      default: false,
    },
    e2eTag: {
      type: String,
      default: 'pickup',
    },
    e2eValue: {
      type: String,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disableSubmit: {
      type: Boolean,
      default: false,
    },
    allowSubmitMaxQuantity: {
      type: Boolean,
      default: false,
    },
  },
  emits: Object.values(Events),
  components: {
    MinusIcon,
    PlusIcon,
    CheckIcon,
    Button,
    Input,
  },
  setup(props, { emit }) {
    const quantity = ref(props.quantity);

    watch(
      () => props.quantity,
      (newValue) => {
        quantity.value = newValue;
      },
    );

    const isValid = computed(
      () =>
        quantity.value >= 0 &&
        quantity.value <= props.quantityOriginalFilteredByStatus,
    );

    const canDecrease = computed(() => quantity.value > 0);

    const canIncrease = computed(
      () =>
        !props.error && quantity.value < props.quantityOriginalFilteredByStatus,
    );

    const updateValue = (value: number | string) => {
      quantity.value = typeof value === 'string' ? parseInt(value) : value;
      emit(Events.Update, quantity);
    };

    const canSubmit = computed(() => {
      if (props.error || props.disableSubmit) {
        return false;
      }
      return (
        quantity.value >= 0 &&
        ((!props.allowSubmitMaxQuantity &&
          quantity.value < props.quantityOriginalFilteredByStatus) ||
          (props.allowSubmitMaxQuantity &&
            quantity.value <= props.quantityOriginalFilteredByStatus))
      );
    });

    const decreaseQuantity = () => {
      if (canDecrease.value) {
        quantity.value -= 1;
        emit(Events.DecreaseTo, quantity.value);
      }
    };

    const increaseQuantity = () => {
      if (canIncrease.value) {
        quantity.value += 1;
        emit(Events.IncreaseTo, quantity.value);
      }
    };

    const handleApplyButton = () => {
      if (canSubmit.value) {
        emit(Events.Apply, props.orderItem, quantity.value);
      }
    };

    const onKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleApplyButton();
        const el = event.target as HTMLElement;
        el.blur();
      }
    };

    const sku = computed<string>(() => {
      return props.orderItem.product
        ? props.orderItem.product.sku
        : props.orderItem.id;
    });

    return {
      canDecrease,
      canIncrease,
      handleApplyButton,
      quantity,
      decreaseQuantity,
      increaseQuantity,
      canSubmit,
      updateValue,
      onKeyPress,
      sku,
      isValid,
      Events,
    };
  },
});
</script>

<style lang="scss" scoped>
.button {
  --width: 36px;
  padding: 0;
  width: var(--width);
  min-width: auto;
  height: var(--width);
}

.footer {
  &__body {
    grid-column: 2/3;
    justify-self: start;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 0 32px;
  }

  &__end {
    justify-self: end;
  }
}

.counter {
  display: grid;
  grid-template-columns: 32px auto auto;
  align-items: center;
  gap: 0 4px;
  font-size: 14px;

  &--labels {
    grid-template-columns: repeat(3, auto);
  }

  &__item {
    font-size: 16px;
    line-height: 1em;
    font-weight: 600;
  }
}

.restore-button {
  margin-top: 16px;
  grid-column: 1/-1;
}
</style>
