import type { LoggerService } from '@/features/core/logger/services/logger-service';
import type { PushNotificationDTO } from '@/features/push-notification/types';
import { getEntityByPushType } from '@/utils/helpers/getEntityByPushType';
import type {
  CompletePipelineWith,
  PipelinePlugin,
} from '@ads/plugin-pipeline';

/**
 * Convert rawEntityType to entityType and store it to DTO
 */

export class RecognizeEntityTypePlugin
  implements PipelinePlugin<PushNotificationDTO>
{
  constructor(private loggerService: LoggerService) {}

  public execute(
    dataTransferObject: PushNotificationDTO,
    completePipelineWith: CompletePipelineWith<PushNotificationDTO>,
  ): PushNotificationDTO {
    const entity = getEntityByPushType(dataTransferObject.rawEntityType ?? '');

    if (!entity) {
      this.loggerService.error(
        `Push handling (${String(
          dataTransferObject.rawEntityType,
        )}): unknown entity`,
        dataTransferObject.eventData,
      );
      completePipelineWith(dataTransferObject);
    }

    dataTransferObject.entityType = entity;
    return dataTransferObject;
  }
}
