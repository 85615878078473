import { loggerServicePlugin } from '@/features/core/logger';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import type { UseOnlineStatus } from '../types';
import { useEventListener } from '@vueuse/core';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';
import type { Cancellable } from '@/utils/types';
import { authServicePlugin } from '@/features/core/auth';
import { $t } from '@/i18n';

try {
  navigator.connection.addEventListener('change', (event) => {
    let data = event;
    try {
      data = JSON.parse(JSON.stringify(event)) as Event;
    } catch (e) {
      // do nothing
    }

    loggerServicePlugin.get().debug('Connection change', data);
  });

  window.addEventListener('offline', function () {
    loggerServicePlugin.get().debug('Device is offline');
  });

  window.addEventListener('online', function () {
    loggerServicePlugin.get().debug('Device is online');
  });
} catch (error: unknown) {
  //do nothing...
}

export const isOnline = ref(window?.navigator.onLine ?? false);

export function useOnlineStatus(): UseOnlineStatus {
  const isOnlineComputed = computed(() => isOnline.value);
  let offlineInfoToast: Cancellable | undefined;
  let offlineErrorToast: Cancellable | undefined;
  const { showOfflineDialog } = authServicePlugin.get();

  useEventListener(window, 'offline', () => {
    isOnline.value = false;
  });

  useEventListener(window, 'online', () => {
    isOnline.value = true;
  });

  const updateOfflineInfoToast = (): void => {
    if (showOfflineDialog.value) return;
    if (isOnline.value) {
      offlineInfoToast?.cancel();
    } else {
      offlineInfoToast = notificationPlugin.get().show({
        text: $t('common.device-offline.text'),
        type: NotificationType.Info,
        duration: -1,
      });
    }
  };
  const updateOfflineErrorToast = (): void => {
    if (isOnline.value) {
      offlineErrorToast?.cancel();
    }
    // Do not display automatically when offline
  };
  const showOfflineErrorToast = (): void => {
    offlineErrorToast = notificationPlugin.get().show({
      text: $t('common.device-offline.text'),
      type: NotificationType.Error,
      duration: 2000,
    });
  };

  const setOnlineStatusHooks = () => {
    onMounted(() => {
      updateOfflineInfoToast();
    });

    watch(isOnlineComputed, () => {
      updateOfflineInfoToast();
      updateOfflineErrorToast();
    });

    onBeforeUnmount(() => {
      offlineInfoToast?.cancel();
      offlineErrorToast?.cancel();
    });
  };

  return {
    isOnline,
    isOnlineComputed,
    showOfflineErrorToast,
    setOnlineStatusHooks,
  };
}
