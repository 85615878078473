import { apiPlugin } from '@/features/core/api';
import { authServicePlugin } from '@/features/core/auth';
import { onlineEntityRepositoryRegistryPlugin } from '@/features/core/entity-repository';
import { errorPlugin } from '@/features/core/errors';
import { loggerServicePlugin } from '@/features/core/logger';
import {
  ExecPluginFactory,
  ProviderPluginFactory,
} from '@/features/core/plugin';
import { storagePlugin } from '@/features/core/storage';
import { imageCachingQueueServicePlugin } from '@/features/imageCachingQueue';
import { syncSchedulerServicePlugin } from '@/features/sync-scheduler';
import { OrderApiClient } from './order';
import { registerOrderApi } from './orderRegister';
import { configurationServicePlugin } from '@/features/configuration';
import type { IOrderApiClient } from '../types';
import { orderMetadataServicePlugin } from '@/features/orderMetadata';
import { processedOrderServicePlugin } from '@/features/processed-order';
import { eventBusServicePlugin } from '@/features/core/event-bus';

export * from './order';
export * from './orderRegister';

export interface OrdersApiClientPlugin {
  order: IOrderApiClient;
}

export const ordersApiPlugin =
  ProviderPluginFactory.create<OrdersApiClientPlugin>({
    key: Symbol('OrdersApiClient'),
    defaultFactory: {
      create: () => ({
        order: new OrderApiClient(
          apiPlugin.get(),
          storagePlugin.get(),
          errorPlugin.get(),
          authServicePlugin.get(),
          imageCachingQueueServicePlugin.get(),
          syncSchedulerServicePlugin.get(),
          loggerServicePlugin.get(),
          configurationServicePlugin.get(),
          orderMetadataServicePlugin.get(),
          processedOrderServicePlugin.get(),
          eventBusServicePlugin.get(),
        ),
      }),
    },
  });

export const ordersApiRegistryPlugin = ExecPluginFactory.fn(() => {
  const { order } = ordersApiPlugin.get();
  registerOrderApi(onlineEntityRepositoryRegistryPlugin.get(), order);
});
