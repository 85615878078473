<template>
  <div>
    <div class="card__footer">
      <div class="left">
        <Button
          v-if="isReplaceBtnExist"
          :disabled="orderItem.quantity === orderItem.quantityOriginal"
          :data-e2e="`picking-order-cancel-button-${sku}`"
          icon
          btnOutline
          @click="onReplace"
        >
          <ReplacementIcon />
        </Button>
        <Button
          v-if="isCancelBtnExist"
          :data-e2e="`picking-order-cancel-button-${sku}`"
          icon
          btnOutline
          @click="setAlertDisplayState(true)"
        >
          <ClearIcon stroke="currentColor" fill="currentColor" />
        </Button>
      </div>
      <WeightBlock
        :sku="orderItem.product?.sku"
        :quantity="quantity"
        :quantity-original="orderItem.quantityOriginal"
        :amount="amount"
        :amount-original="orderItem.amountOriginal"
        :count-weight="orderItem.product?.rwpType === 1"
        :unit="orderItem.unit"
      />
      <div class="right">
        <Button
          v-if="hasReplacement"
          icon
          btnOutline
          @click="onHandleReplace"
          :data-e2e="`picking-edit-button-${sku}`"
          class="has-replacement"
        >
          <EditIcon />
        </Button>
        <Button
          v-else
          :data-e2e="`picking-picked-button-${sku}`"
          icon
          btnOutline
          @click="onEdit"
        >
          <EditIcon />
        </Button>
      </div>
    </div>
    <div
      v-if="showCustomerNote && orderItem.cartNote"
      :data-e2e="`item-customer-note-${sku}`"
      class="customer-note-text"
    >
      <span>Customer Note</span>: {{ orderItem.cartNote }}
    </div>
  </div>
  <div v-if="isAlertDisplay" class="card__alert alert">
    <p class="alert__title">
      {{
        $t(
          'components.picking-product-card-footer-with-weight.alert-title.text',
          {
            orderItemAmountRounded: roundValue(orderItem.amount),
            orderItemAmountOriginalRounded: roundValue(
              orderItem.amountOriginal,
            ),
            orderItemUnit: unitToDisplay(
              orderItem.unit,
              roundValue(orderItem.amount),
            ),
          },
        )
      }}
    </p>
    <div class="alert__footer">
      <Button
        btnOutline
        @click="onMoveToNotFound"
        :data-e2e="`picking-confirm-button-${sku}`"
      >
        {{
          $t(
            'components.picking-product-card-footer-with-weight.alert-confirm.text',
          )
        }}
      </Button>
      <Button
        plain
        btnOutline
        @click="setAlertDisplayState(false)"
        :data-e2e="`picking-cancel-button-${sku}`"
      >
        {{
          $t(
            'components.picking-product-card-footer-with-weight.alert-cancel.text',
          )
        }}
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent, ref } from 'vue';
import { Button, WeightBlock } from '@/features/ui/components';
import type { OrderItem } from '@/features/orders/types';
import { ClearIcon, EditIcon, ReplacementIcon } from '@/features/ui/icons';
import { roundValue } from '@/utils/helpers/roundValue';
import { unitToDisplay } from '@/utils/helpers/unitPluralHandler';
import { $t } from '@/i18n';

export default defineComponent({
  name: 'ProductCardFooterTypeWithWeight',
  methods: { $t, unitToDisplay },
  props: {
    orderItem: {
      type: Object as PropType<OrderItem>,
      default: () => ({}),
    },
    currentTab: String,
    isCancelBtnExist: {
      type: Boolean,
      default: false,
    },
    isReplaceBtnExist: {
      type: Boolean,
      default: false,
    },
    hasReplacement: {
      type: Boolean,
      default: false,
    },
    showCustomerNote: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['edit', 'moveToNotFound', 'return', 'replace', 'handle-replace'],
  components: {
    WeightBlock,
    ClearIcon,
    Button,
    EditIcon,
    ReplacementIcon,
  },
  setup(props, { emit }) {
    const isAlertDisplay = ref(false);

    const setAlertDisplayState = (value: boolean) => {
      isAlertDisplay.value = value;
    };

    const onEdit = () => {
      emit('edit', props.orderItem.id);
    };

    const onHandleReplace = () => {
      emit('handle-replace', props.orderItem);
    };

    const onMoveToNotFound = () => {
      emit('moveToNotFound', props.orderItem.id);
    };

    const sku = computed<string>(() => {
      return props.orderItem.product
        ? props.orderItem.product.sku
        : props.orderItem.id;
    });

    const quantity = computed<number>(() => {
      return props.orderItem.quantity;
    });

    const amount = computed<number>(() => {
      return props.orderItem.amount;
    });

    const onReplace = () => {
      emit('replace', props.orderItem);
    };

    return {
      onEdit,
      onHandleReplace,
      onMoveToNotFound,
      sku,
      quantity,
      amount,
      isAlertDisplay,
      setAlertDisplayState,
      onReplace,
      roundValue,
    };
  },
});
</script>

<style lang="scss" scoped>
.text-block__padding {
  padding: 0 7px;
}
.active {
  border-color: #0d3a93;
}
.passive {
  border-color: transparent;
}
.card {
  position: relative;
  overflow: hidden;
  padding: 18px 14px 14px;
  background: #fff;
  border-style: solid;
  border-width: 2px;
  border-radius: 20px;
  box-shadow: 0 3px 16px rgba(13, 58, 147, 0.12);
  transition: border-color ease-in 0.2s;

  &.picked {
    border: 2px solid #0d3a93;
  }

  &__title {
    margin: 0 auto;
    max-width: 50%;
    font-size: 16px;
    line-height: 1.4em;
    font-weight: 600;
  }
  &__subtitle {
    margin: 4px 0;
    font-size: 8px;
    line-height: 1.4em;
    letter-spacing: 0.04em;
  }
  &__body {
    margin-top: 5px;
  }
  &__footer {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    grid-template-columns: 1fr auto 1fr;
    gap: 0 16px;
  }
  &__alert {
    position: absolute;
    top: 0;
    left: 0;
    padding: 24px 16px 16px;
    width: 100%;
    height: 100%;
    background-color: white;
  }
}
.counter {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  gap: 0 8px;
  font-size: 12px;
  &__item {
    font-size: 16px;
    line-height: 1em;
    font-weight: 600;
  }
  &__block_bordered {
    border-left: 1px solid #b6c4df;
    padding-left: 8px;
  }
}
.alert {
  display: grid;
  gap: 32px 0;
  align-content: center;
  &__title {
    margin: 0 auto;
    width: 86%;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.44em;
  }
  &__footer {
    display: grid;
    gap: 16px 0;
  }
}
.product-img {
  margin: 0 auto;
  width: 112px;
  min-width: 11px;
  height: 112px;
}

.left,
.right {
  display: grid;
  grid-auto-flow: column;
  gap: 0 16px;
}
.left {
  justify-self: left;
}
.right {
  justify-self: right;
}
.has-replacement {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 2;
}
.customer-note-text {
  margin-top: 4px;
  span {
    font-weight: bold;
    color: $primary-color;
  }
}
</style>
