
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/features/core/router';
import { oauthServicePlugin } from '@/features/oauth';
import { userServicePlugin, useUser } from '@/features/user';
import type { StorageEntityRepository } from '@/features/core/entity-repository';
import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { useFeatureToggle } from '@/features/feature-toggle';
import { useTransmissions } from '@/features/transmissions';
import Button from '@/features/ui/components/Button.vue';
import LogoutButton from '@/features/ui/components/LogoutButton.vue';
import { ChevronRight, InfoIcon, LoadingIcon } from '@/features/ui/icons';
import { useProfileReceiveData } from '../composables/useProfileReceiveData';
import InfoMenu from '../components/InfoMenu.vue';
import { configurationServicePlugin } from '@/features/configuration';

export default defineComponent({
  name: 'Profile',
  components: {
    LogoutButton,
    InfoIcon,
    ChevronRight,
    LoadingIcon,
    Button,
    InfoMenu,
  },
  emits: ['update'],
  setup(props, { emit }) {
    const route = useRoute();
    const { user, loading, loadUser } = useUser();

    const { refreshAll } = useProfileReceiveData();
    const isLogoutDisabled = computed(
      () =>
        route.meta?.disabledLogout === true ||
        hasPendingTransmissions.value ||
        hasFailedTransmissions.value,
    );

    const showUserReportLink = ref(false);

    onMounted(async () => {
      await loadUser();
      initTransmissionsCheck();
      showUserReportLink.value = (
        await configurationServicePlugin.get().isFeatureActive('bugReporting')
      ).value;
    });

    const infoMenuOpen = ref(false);
    const toggleInfoMenu = () => {
      infoMenuOpen.value = !infoMenuOpen.value;
    };

    const logout = async () => {
      if (hasPendingTransmissions.value) {
        return;
      }

      await oauthServicePlugin.get().logout('empowerId');
      await userServicePlugin.get().clearUser();
      (entityRepositoryPlugin.get() as StorageEntityRepository).clearRefCache();
    };

    const receiveData = async () => {
      await refreshAll({
        refreshOrders: true,
        removeSyncData: true,
      });

      if (route?.name !== 'default') {
        return await router.get().push('/');
      }

      emit('update');
    };

    //#region transmissions
    const {
      hasPendingTransmissions,
      hasFailedTransmissions,
      toggleTransmissionsWindowVisibility,
      initTransmissionsCheck,
    } = useTransmissions();
    //#endregion

    //#region feature list
    const { featureList } = useFeatureToggle();

    const showReceiveDataButton = computed(
      () =>
        process.env.VUE_APP_SHOW_RECEIVE_DATA_BUTTON === 'true' ||
        featureList.value.manualDataLoading.active,
    );
    //#endregion

    const pushUserReport = async () => {
      await router.get().push({
        name: 'user-report',
      });
    };

    return {
      logout,
      receiveData,
      user,
      loading,
      isLogoutDisabled,
      hasPendingTransmissions,
      hasFailedTransmissions,
      toggleTransmissionsWindowVisibility,
      showReceiveDataButton,
      toggleInfoMenu,
      infoMenuOpen,
      showUserReportLink,
      pushUserReport,
      InfoMenu,
    };
  },
});
